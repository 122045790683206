import $ from 'jquery';
import { copyElementDataToClipboard } from '@/lib/clipboard';

function initClipboardTrigger() {
  $(document).on('click', '.js-clipboard', (e) => {
    e.preventDefault();
    const { currentTarget } = e;
    copyElementDataToClipboard(currentTarget);
  });
}

export default initClipboardTrigger;
