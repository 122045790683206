function initCopyOfficialNameToName() {
  $('.js-user-official-name').on('blur', function(e) {
    const $nameInput = $('.js-user-name');
    if ($nameInput.val() === '') {
      $nameInput.val($(this).val());
    }
  });
}

function initUserPageTabs() {
  $(document).on('click', '.js-user-tab-month-link', function() {
    const $userTabMonthLink = $(this);
    $('.js-loading-user-month-tasks-on-' + $userTabMonthLink.data('month')).show();
    $('.js-user-month-tasks-on-' + $userTabMonthLink.data('month')).html('');
    $.ajax({
      url: $userTabMonthLink.data('url'),
      dataType: 'script',
      method: 'GET'
    });
  });
}

$(document).on('turbolinks:load', function() {
  initUserPageTabs();
  initCopyOfficialNameToName();
});
