import { Temporal } from '@js-temporal/polyfill';

function padNumber(n: number, digits: number) {
  return n.toString().padStart(digits, '0');
}

export function formatIsoDateTimeString(isoTimeString: string, timeZone: string) {
  const instant = Temporal.Instant.from(isoTimeString);
  const zdt = instant.toZonedDateTimeISO(timeZone);
  return `${zdt.year}/${padNumber(zdt.month, 2)}/${padNumber(zdt.day, 2)} ${padNumber(zdt.hour, 2)}:${padNumber(zdt.minute, 2)}`;
}
