import polyglot from './polyglot';

let pageDirty = false;
const message = polyglot.t('js.beforeunload.warning')

window.addEventListener("beforeunload", (event) => {
  if (pageDirty) {
    event.returnValue = message;
    return event.returnValue;
  }
});

// turbolinks では beforeunload イベントが起きないので専用のイベントをフックする
// (ただし、ブラウザバックボタンの場合はフックできない)
document.addEventListener('turbolinks:before-visit', (event) => {
  if (!pageDirty) return;

  if (!confirm(message)) {
      event.preventDefault();
  }
});

document.addEventListener('turbolinks:load', () => {
  pageDirty = false;

  const form = document.querySelector('.js-confirm-on-leaving-form');
  if (form == null) return;

  if (form.classList.contains('js-form-has-errors')) {
    pageDirty = true;
  }

  form.addEventListener('change', () => {
    pageDirty = true;
  });

  form.addEventListener('submit', () => {
    pageDirty = false;
  })
});
