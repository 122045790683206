import $ from 'jquery';

function toggleMissingUserBills(e) {
  e.preventDefault();
  $('.js-valid-user-bill-row').toggleClass('hidden');
}

$(document).on('turbolinks:load', () => {
  $('.js-toggle-valid-user-bill-row').on('click', toggleMissingUserBills);
});
