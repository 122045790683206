import * as React from 'react';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';

type Option<T extends string> = {
  value: T,
  text: string,
};

type Props<T extends string> = LabelProps & {
  value?: string,
  options: Option<T>[],
  name?: string | undefined,
  wrapperClassName?: string | undefined,
  readOnly?: boolean,
  disabled?: boolean,
  min?: number | undefined,
  max?: number | undefined,
  onChange?: (value: T) => void,
}

const labelStyle = (disabled: boolean | undefined): React.CSSProperties => disabled ? ({ cursor: 'not-allowed', userSelect: 'none' }) : ({ userSelect: 'none' });

const RadioGroupInput = <T extends string>(props: Props<T>) => {
  const { label, id, required } = props;
  return (
    <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
      <input type="hidden" name={props.name} value="" autoComplete="off" disabled={props.disabled} />
      {
        props.options.map((opt) =>
          <span className="radio" key={opt.value} >
            <label >
              <input
                type="radio"
                name={props.name}
                value={opt.value}
                onChange={(e) => e.target.checked && props.onChange && props.onChange(opt.value)}
                checked={props.value === opt.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
              />
              <span style={labelStyle(props.disabled)}>{ opt.text }</span>
            </label>
          </span>
        )
      }
    </FormGroup>
  );
}
export default RadioGroupInput;
