import * as React from 'react';
import Select from 'react-select';
import type { OnChangeValue, ActionMeta } from 'react-select';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';
import { multiSelectCustomStyles } from '@/workforce/components/utils/ReactSelectUtils';
import type { SelectOption } from '@/workforce/components/utils/ReactSelectUtils';

type IsMulti = true;

type Props = LabelProps & {
  value?: readonly string[],
  name?: string | undefined,
  options: readonly SelectOption[],
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  required?: boolean,
  disabled?: boolean,
  onChange?: (value: string[]) => void,
}

const SearchMultiSelectInput = (props: Props) => {
  const { label, id, required, options } = props;
  const currentValue = options.filter((opt) => props.value != null ? props.value.some((selected) => selected == opt.value) : []);

  const onChange = (value: OnChangeValue<SelectOption, IsMulti>, actionMeta: ActionMeta<SelectOption>) => {
    props.onChange && props.onChange(value.map((opt) => opt.value));
  };

  return (
    <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
      <Select
        id={props.id}
        name={props.name}
        isMulti
        isClearable
        isDisabled={props.disabled}
        styles={multiSelectCustomStyles}
        value={currentValue}
        onChange={onChange}
        options={options}
      />
    </FormGroup>
  );
}
export default SearchMultiSelectInput;
