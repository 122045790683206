/* eslint-disable */
// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number | (string | number)[];
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (Object.prototype.hasOwnProperty.call(params, param)) {
    if (keys.indexOf(param) === -1) {
      if (Array.isArray(params[param])) {
        for (var value of params[param] as (string | number)[]) {
          query.push(param + "[]=" + encodeURIComponent(value.toString()));
        }
      } else {
        query.push(param + "=" + encodeURIComponent(params[param].toString()));
      }
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function staffRootPath(params?: Record<string, Value>) { return process('/stf', params, []); }
export function stfBlazerPath(params?: Record<string, Value>) { return process('/stf/blazer', params, []); }
export function newStfTwoFactorAuthSessionPath(params?: Record<string, Value>) { return process('/stf/two_factor_auth_session/new', params, []); }
export function stfTwoFactorAuthSessionPath(params?: Record<string, Value>) { return process('/stf/two_factor_auth_session', params, []); }
export function newStfTwoFactorAuthPath(params?: Record<string, Value>) { return process('/stf/two_factor_auth/new', params, []); }
export function stfTwoFactorAuthPath(params?: Record<string, Value>) { return process('/stf/two_factor_auth', params, []); }
export function stfMasqueradePath(params?: Record<string, Value>) { return process('/stf/masquerade', params, []); }
export function updateAvailabilityStatusStfMyProfilePath(params?: Record<string, Value>) { return process('/stf/my/profile/update_availability_status', params, []); }
export function editStfMyProfilePath(params?: Record<string, Value>) { return process('/stf/my/profile/edit', params, []); }
export function stfMyProfilePath(params?: Record<string, Value>) { return process('/stf/my/profile', params, []); }
export function stfMySlackStaffAccountsPath(params?: Record<string, Value>) { return process('/stf/my/slack_staff_accounts', params, []); }
export function stfMySlackStaffAccountPath(params: Params<'id'>) { return process('/stf/my/slack_staff_accounts/' + params.id + '', params, ['id']); }
export function stfMyWorkStatisticsPath(params?: Record<string, Value>) { return process('/stf/my/work_statistics', params, []); }
export function stfMyWorkHistoriesPath(params?: Record<string, Value>) { return process('/stf/my/work_histories', params, []); }
export function stfMyStaffWagesPath(params?: Record<string, Value>) { return process('/stf/my/staff_wages', params, []); }
export function newStfMyRankConfirmationPath(params?: Record<string, Value>) { return process('/stf/my/rank_confirmation/new', params, []); }
export function stfMyRankConfirmationPath(params?: Record<string, Value>) { return process('/stf/my/rank_confirmation', params, []); }
export function stfMyWorkingMinutesPath(params?: Record<string, Value>) { return process('/stf/my/working_minutes', params, []); }
export function stfMyNtaRegistrationPath(params?: Record<string, Value>) { return process('/stf/my/nta_registration', params, []); }
export function stfMyNtaRegistrationApiCallsPath(params?: Record<string, Value>) { return process('/stf/my/nta_registration_api_calls', params, []); }
export function newStfMyNtaRegistrationApiCallPath(params?: Record<string, Value>) { return process('/stf/my/nta_registration_api_calls/new', params, []); }
export function stfMyNtaRegistrationApiCallPath(params: Params<'id'>) { return process('/stf/my/nta_registration_api_calls/' + params.id + '', params, ['id']); }
export function companiesStfAcZuoraExportsPath(params?: Record<string, Value>) { return process('/stf/ac/zuora_exports/companies', params, []); }
export function clientsStfAcZuoraExportsPath(params?: Record<string, Value>) { return process('/stf/ac/zuora_exports/clients', params, []); }
export function contractsStfAcZuoraExportsPath(params?: Record<string, Value>) { return process('/stf/ac/zuora_exports/contracts', params, []); }
export function stfAcZuoraExportsPath(params?: Record<string, Value>) { return process('/stf/ac/zuora_exports', params, []); }
export function stfAcSlackBotAccountsPath(params?: Record<string, Value>) { return process('/stf/ac/slack_bot_accounts', params, []); }
export function editStfAcSlackBotAccountPath(params: Params<'id'>) { return process('/stf/ac/slack_bot_accounts/' + params.id + '/edit', params, ['id']); }
export function stfAcSlackBotAccountPath(params: Params<'id'>) { return process('/stf/ac/slack_bot_accounts/' + params.id + '', params, ['id']); }
export function stfAcStaffPrivilegesPath(params?: Record<string, Value>) { return process('/stf/ac/staff_privileges', params, []); }
export function stfAcStaffPrivilegePath(params: Params<'id'>) { return process('/stf/ac/staff_privileges/' + params.id + '', params, ['id']); }
export function stfAcTwoFactorAuthsPath(params?: Record<string, Value>) { return process('/stf/ac/two_factor_auths', params, []); }
export function stfAcTwoFactorAuthPath(params: Params<'id'>) { return process('/stf/ac/two_factor_auths/' + params.id + '', params, ['id']); }
export function stfAcArchivedUsersPath(params?: Record<string, Value>) { return process('/stf/ac/archived_users', params, []); }
export function stfAcArchivedUserPath(params: Params<'id'>) { return process('/stf/ac/archived_users/' + params.id + '', params, ['id']); }
export function stfAcCustomHolidaysPath(params?: Record<string, Value>) { return process('/stf/ac/custom_holidays', params, []); }
export function newStfAcCustomHolidayPath(params?: Record<string, Value>) { return process('/stf/ac/custom_holidays/new', params, []); }
export function stfAcCustomHolidayPath(params: Params<'id'>) { return process('/stf/ac/custom_holidays/' + params.id + '', params, ['id']); }
export function stfAcUserContractPlansPath(params?: Record<string, Value>) { return process('/stf/ac/user_contract_plans', params, []); }
export function newStfAcUserContractPlanPath(params?: Record<string, Value>) { return process('/stf/ac/user_contract_plans/new', params, []); }
export function editStfAcUserContractPlanPath(params: Params<'id'>) { return process('/stf/ac/user_contract_plans/' + params.id + '/edit', params, ['id']); }
export function stfAcUserContractPlanPath(params: Params<'id'>) { return process('/stf/ac/user_contract_plans/' + params.id + '', params, ['id']); }
export function approveAllStfAcContractUpdatesPath(params?: Record<string, Value>) { return process('/stf/ac/contract_updates/approve_all', params, []); }
export function currentContractStfAcContractUpdatesPath(params?: Record<string, Value>) { return process('/stf/ac/contract_updates/current_contract', params, []); }
export function stfAcContractUpdatesPath(params?: Record<string, Value>) { return process('/stf/ac/contract_updates', params, []); }
export function newStfAcContractUpdatePath(params?: Record<string, Value>) { return process('/stf/ac/contract_updates/new', params, []); }
export function stfAcContractUpdatePath(params: Params<'id'>) { return process('/stf/ac/contract_updates/' + params.id + '', params, ['id']); }
export function checkStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/check', params, []); }
export function createForMonthStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/create_for_month', params, []); }
export function bulkSentStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/bulk_sent', params, []); }
export function bulkUnsentStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/bulk_unsent', params, []); }
export function bulkUpdateStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/bulk_update', params, []); }
export function bulkDestroyStfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills/bulk_destroy', params, []); }
export function stfAcUserBillsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bills', params, []); }
export function editStfAcUserBillPath(params: Params<'id'>) { return process('/stf/ac/user_bills/' + params.id + '/edit', params, ['id']); }
export function stfAcUserBillPath(params: Params<'id'>) { return process('/stf/ac/user_bills/' + params.id + '', params, ['id']); }
export function stfAcTimeTransferUserBillPath(params?: Record<string, Value>) { return process('/stf/ac/time_transfer_user_bill', params, []); }
export function stfAcRoboDemandSyncsPath(params?: Record<string, Value>) { return process('/stf/ac/robo_demand_syncs', params, []); }
export function stfAcRoboDemandSyncPath(params: Params<'id'>) { return process('/stf/ac/robo_demand_syncs/' + params.id + '', params, ['id']); }
export function newStfAcBulkUserBillPath(params?: Record<string, Value>) { return process('/stf/ac/bulk_user_bill/new', params, []); }
export function editStfAcBulkUserBillPath(params?: Record<string, Value>) { return process('/stf/ac/bulk_user_bill/edit', params, []); }
export function stfAcBulkUserBillPath(params?: Record<string, Value>) { return process('/stf/ac/bulk_user_bill', params, []); }
export function resolveStfAcUserBillCollisionsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_collisions/resolve', params, []); }
export function resolvedStfAcUserBillCollisionsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_collisions/resolved', params, []); }
export function stfAcUserBillCollisionsPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_collisions', params, []); }
export function stfAcOvertimesPath(params?: Record<string, Value>) { return process('/stf/ac/overtimes', params, []); }
export function previewStfAcUserBillItemsImporterPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_items_importer/preview', params, []); }
export function sampleCsvStfAcUserBillItemsImporterPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_items_importer/sample_csv', params, []); }
export function newStfAcUserBillItemsImporterPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_items_importer/new', params, []); }
export function stfAcUserBillItemsImporterPath(params?: Record<string, Value>) { return process('/stf/ac/user_bill_items_importer', params, []); }
export function categoriesStfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard/categories', params, []); }
export function usersStfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard/users', params, []); }
export function buildStfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard/build', params, []); }
export function newStfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard/new', params, []); }
export function editStfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard/edit', params, []); }
export function stfAcDashboardPath(params?: Record<string, Value>) { return process('/stf/ac/dashboard', params, []); }
export function recreateStaffBillsStfAcStaffBillsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_bills/recreate_staff_bills', params, []); }
export function zenginCsvStfAcStaffBillsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_bills/zengin_csv', params, []); }
export function bankTransferCsvStfAcStaffBillsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_bills/bank_transfer_csv', params, []); }
export function stfAcStaffBillsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_bills', params, []); }
export function stfAcStaffBillPath(params: Params<'id'>) { return process('/stf/ac/staff_bills/' + params.id + '', params, ['id']); }
export function destroyInvitationStfAcStaffPath(params: Params<'id'>) { return process('/stf/ac/staffs/' + params.id + '/destroy_invitation', params, ['id']); }
export function stfAcStaffWorkStatisticsPath(params: Params<'staffId'>) { return process('/stf/ac/staffs/' + params.staffId + '/work_statistics', params, ['staffId']); }
export function stfAcStaffWorkHistoriesPath(params: Params<'staffId'>) { return process('/stf/ac/staffs/' + params.staffId + '/work_histories', params, ['staffId']); }
export function stfAcStaffStaffWagesPath(params: Params<'staffId'>) { return process('/stf/ac/staffs/' + params.staffId + '/staff_wages', params, ['staffId']); }
export function newStfAcStaffStaffWagePath(params: Params<'staffId'>) { return process('/stf/ac/staffs/' + params.staffId + '/staff_wages/new', params, ['staffId']); }
export function editStfAcStaffStaffWagePath(params: Params<'staffId' | 'id'>) { return process('/stf/ac/staffs/' + params.staffId + '/staff_wages/' + params.id + '/edit', params, ['staffId','id']); }
export function stfAcStaffStaffWagePath(params: Params<'staffId' | 'id'>) { return process('/stf/ac/staffs/' + params.staffId + '/staff_wages/' + params.id + '', params, ['staffId','id']); }
export function stfAcStaffWorkingMinutesPath(params: Params<'staffId'>) { return process('/stf/ac/staffs/' + params.staffId + '/working_minutes', params, ['staffId']); }
export function stfAcStaffsPath(params?: Record<string, Value>) { return process('/stf/ac/staffs', params, []); }
export function newStfAcStaffPath(params?: Record<string, Value>) { return process('/stf/ac/staffs/new', params, []); }
export function editStfAcStaffPath(params: Params<'id'>) { return process('/stf/ac/staffs/' + params.id + '/edit', params, ['id']); }
export function stfAcStaffPath(params: Params<'id'>) { return process('/stf/ac/staffs/' + params.id + '', params, ['id']); }
export function updateAllStfAcNtaRegistrationsPath(params?: Record<string, Value>) { return process('/stf/ac/nta_registrations/update_all', params, []); }
export function stfAcNtaRegistrationsPath(params?: Record<string, Value>) { return process('/stf/ac/nta_registrations', params, []); }
export function stfAcNtaRegistrationPath(params: Params<'id'>) { return process('/stf/ac/nta_registrations/' + params.id + '', params, ['id']); }
export function approvalCsvStfAcStaffWageConfirmationsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_wage_confirmations/approval_csv', params, []); }
export function approveAllStfAcStaffWageConfirmationsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_wage_confirmations/approve_all', params, []); }
export function createAllStfAcStaffWageConfirmationsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_wage_confirmations/create_all', params, []); }
export function destroyAllStfAcStaffWageConfirmationsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_wage_confirmations/destroy_all', params, []); }
export function stfAcStaffWageConfirmationsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_wage_confirmations', params, []); }
export function stfAcStaffWageConfirmationPath(params: Params<'id'>) { return process('/stf/ac/staff_wage_confirmations/' + params.id + '', params, ['id']); }
export function stfAcDivisionsPath(params?: Record<string, Value>) { return process('/stf/ac/divisions', params, []); }
export function newStfAcDivisionPath(params?: Record<string, Value>) { return process('/stf/ac/divisions/new', params, []); }
export function editStfAcDivisionPath(params: Params<'id'>) { return process('/stf/ac/divisions/' + params.id + '/edit', params, ['id']); }
export function stfAcDivisionPath(params: Params<'id'>) { return process('/stf/ac/divisions/' + params.id + '', params, ['id']); }
export function stfAcServiceTypesPath(params?: Record<string, Value>) { return process('/stf/ac/service_types', params, []); }
export function newStfAcServiceTypePath(params?: Record<string, Value>) { return process('/stf/ac/service_types/new', params, []); }
export function editStfAcServiceTypePath(params: Params<'id'>) { return process('/stf/ac/service_types/' + params.id + '/edit', params, ['id']); }
export function stfAcServiceTypePath(params: Params<'id'>) { return process('/stf/ac/service_types/' + params.id + '', params, ['id']); }
export function stfAcServiceDocumentsPath(params?: Record<string, Value>) { return process('/stf/ac/service_documents', params, []); }
export function newStfAcServiceDocumentPath(params?: Record<string, Value>) { return process('/stf/ac/service_documents/new', params, []); }
export function editStfAcServiceDocumentPath(params: Params<'id'>) { return process('/stf/ac/service_documents/' + params.id + '/edit', params, ['id']); }
export function stfAcServiceDocumentPath(params: Params<'id'>) { return process('/stf/ac/service_documents/' + params.id + '', params, ['id']); }
export function stfAcTaskCategoriesPath(params?: Record<string, Value>) { return process('/stf/ac/task_categories', params, []); }
export function newStfAcTaskCategoryPath(params?: Record<string, Value>) { return process('/stf/ac/task_categories/new', params, []); }
export function editStfAcTaskCategoryPath(params: Params<'id'>) { return process('/stf/ac/task_categories/' + params.id + '/edit', params, ['id']); }
export function stfAcTaskCategoryPath(params: Params<'id'>) { return process('/stf/ac/task_categories/' + params.id + '', params, ['id']); }
export function stfAcSkillCategoriesPath(params?: Record<string, Value>) { return process('/stf/ac/skill_categories', params, []); }
export function newStfAcSkillCategoryPath(params?: Record<string, Value>) { return process('/stf/ac/skill_categories/new', params, []); }
export function editStfAcSkillCategoryPath(params: Params<'id'>) { return process('/stf/ac/skill_categories/' + params.id + '/edit', params, ['id']); }
export function stfAcSkillCategoryPath(params: Params<'id'>) { return process('/stf/ac/skill_categories/' + params.id + '', params, ['id']); }
export function stfAcUserDepartmentsPath(params?: Record<string, Value>) { return process('/stf/ac/user_departments', params, []); }
export function newStfAcUserDepartmentPath(params?: Record<string, Value>) { return process('/stf/ac/user_departments/new', params, []); }
export function editStfAcUserDepartmentPath(params: Params<'id'>) { return process('/stf/ac/user_departments/' + params.id + '/edit', params, ['id']); }
export function stfAcUserDepartmentPath(params: Params<'id'>) { return process('/stf/ac/user_departments/' + params.id + '', params, ['id']); }
export function stfAcStaffSkillsPath(params?: Record<string, Value>) { return process('/stf/ac/staff_skills', params, []); }
export function stfAcTeamsPath(params?: Record<string, Value>) { return process('/stf/ac/teams', params, []); }
export function newStfAcTeamPath(params?: Record<string, Value>) { return process('/stf/ac/teams/new', params, []); }
export function editStfAcTeamPath(params: Params<'id'>) { return process('/stf/ac/teams/' + params.id + '/edit', params, ['id']); }
export function stfAcTeamPath(params: Params<'id'>) { return process('/stf/ac/teams/' + params.id + '', params, ['id']); }
export function stfAcTaskQualityChecksPath(params?: Record<string, Value>) { return process('/stf/ac/task_quality_checks', params, []); }
export function newStfAcTaskQualityCheckPath(params?: Record<string, Value>) { return process('/stf/ac/task_quality_checks/new', params, []); }
export function editStfAcTaskQualityCheckPath(params: Params<'id'>) { return process('/stf/ac/task_quality_checks/' + params.id + '/edit', params, ['id']); }
export function stfAcTaskQualityCheckPath(params: Params<'id'>) { return process('/stf/ac/task_quality_checks/' + params.id + '', params, ['id']); }
export function stfAcChatworkBotsPath(params?: Record<string, Value>) { return process('/stf/ac/chatwork_bots', params, []); }
export function newStfAcChatworkBotPath(params?: Record<string, Value>) { return process('/stf/ac/chatwork_bots/new', params, []); }
export function editStfAcChatworkBotPath(params: Params<'id'>) { return process('/stf/ac/chatwork_bots/' + params.id + '/edit', params, ['id']); }
export function stfAcChatworkBotPath(params: Params<'id'>) { return process('/stf/ac/chatwork_bots/' + params.id + '', params, ['id']); }
export function stfFrontRootPath(params?: Record<string, Value>) { return process('/stf/front', params, []); }
export function stfFrontTasksPath(params?: Record<string, Value>) { return process('/stf/front/tasks', params, []); }
export function readStfNotificationPath(params: Params<'id'>) { return process('/stf/notifications/' + params.id + '/read', params, ['id']); }
export function unreadStfNotificationPath(params: Params<'id'>) { return process('/stf/notifications/' + params.id + '/unread', params, ['id']); }
export function readAllStfNotificationsPath(params?: Record<string, Value>) { return process('/stf/notifications/read_all', params, []); }
export function stfNotificationsPath(params?: Record<string, Value>) { return process('/stf/notifications', params, []); }
export function stfNotificationPath(params: Params<'id'>) { return process('/stf/notifications/' + params.id + '', params, ['id']); }
export function stfEmailEventsPath(params?: Record<string, Value>) { return process('/stf/email_events', params, []); }
export function stfEmailEventPath(params: Params<'id'>) { return process('/stf/email_events/' + params.id + '', params, ['id']); }
export function stfProfilePath(params?: Record<string, Value>) { return process('/stf/profile', params, []); }
export function stfBankPath(params: Params<'id'>) { return process('/stf/banks/' + params.id + '', params, ['id']); }
export function stfWorkingTimetablesPath(params?: Record<string, Value>) { return process('/stf/working_timetables', params, []); }
export function newStfWorkingTimetablePath(params?: Record<string, Value>) { return process('/stf/working_timetables/new', params, []); }
export function stfWorkingTimetablePath(params: Params<'id'>) { return process('/stf/working_timetables/' + params.id + '', params, ['id']); }
export function finishStfRoutinePath(params: Params<'id'>) { return process('/stf/routines/' + params.id + '/finish', params, ['id']); }
export function stfRoutineRoutineCommentsPath(params: Params<'routineId'>) { return process('/stf/routines/' + params.routineId + '/routine_comments', params, ['routineId']); }
export function stfRoutineRoutineCommentPath(params: Params<'routineId' | 'id'>) { return process('/stf/routines/' + params.routineId + '/routine_comments/' + params.id + '', params, ['routineId','id']); }
export function stfRoutinesPath(params?: Record<string, Value>) { return process('/stf/routines', params, []); }
export function newStfRoutinePath(params?: Record<string, Value>) { return process('/stf/routines/new', params, []); }
export function editStfRoutinePath(params: Params<'id'>) { return process('/stf/routines/' + params.id + '/edit', params, ['id']); }
export function stfRoutinePath(params: Params<'id'>) { return process('/stf/routines/' + params.id + '', params, ['id']); }
export function finishStfProjectPath(params: Params<'id'>) { return process('/stf/projects/' + params.id + '/finish', params, ['id']); }
export function cancelStfProjectPath(params: Params<'id'>) { return process('/stf/projects/' + params.id + '/cancel', params, ['id']); }
export function stfProjectsPath(params?: Record<string, Value>) { return process('/stf/projects', params, []); }
export function newStfProjectPath(params?: Record<string, Value>) { return process('/stf/projects/new', params, []); }
export function editStfProjectPath(params: Params<'id'>) { return process('/stf/projects/' + params.id + '/edit', params, ['id']); }
export function stfProjectPath(params: Params<'id'>) { return process('/stf/projects/' + params.id + '', params, ['id']); }
export function newStfStaffSearchPath(params?: Record<string, Value>) { return process('/stf/staff_search/new', params, []); }
export function editStfStaffSearchPath(params?: Record<string, Value>) { return process('/stf/staff_search/edit', params, []); }
export function stfStaffSearchPath(params?: Record<string, Value>) { return process('/stf/staff_search', params, []); }
export function skillsStfStaffPath(params: Params<'id'>) { return process('/stf/staffs/' + params.id + '/skills', params, ['id']); }
export function oldIndexStfStaffsPath(params?: Record<string, Value>) { return process('/stf/staffs/old_index', params, []); }
export function stfStaffsPath(params?: Record<string, Value>) { return process('/stf/staffs', params, []); }
export function stfStaffPath(params: Params<'id'>) { return process('/stf/staffs/' + params.id + '', params, ['id']); }
export function updateQualityCheckStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/update_quality_check', params, ['id']); }
export function backToNewStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/back_to_new', params, ['id']); }
export function backToTemporaryAssignedStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/back_to_temporary_assigned', params, ['id']); }
export function recruitStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/recruit', params, ['id']); }
export function backToRecruitingStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/back_to_recruiting', params, ['id']); }
export function completeStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/complete', params, ['id']); }
export function approveStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/approve', params, ['id']); }
export function cancelStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/cancel', params, ['id']); }
export function backToOngoingStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/back_to_ongoing', params, ['id']); }
export function backToWaitingApprovalStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/back_to_waiting_approval', params, ['id']); }
export function bulkCopyStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/bulk_copy', params, ['id']); }
export function removeFileStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/remove_file', params, ['id']); }
export function editTagsStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/edit_tags', params, ['id']); }
export function updateTagsStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/update_tags', params, ['id']); }
export function startFromTemporaryAssignStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/start_from_temporary_assign', params, ['id']); }
export function setStaffsStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/set_staffs', params, ['id']); }
export function tagsStfTasksPath(params?: Record<string, Value>) { return process('/stf/tasks/tags', params, []); }
export function bulkActionStfTasksPath(params?: Record<string, Value>) { return process('/stf/tasks/bulk_action', params, []); }
export function stfTaskAssignmentsPath(params: Params<'taskId'>) { return process('/stf/tasks/' + params.taskId + '/assignments', params, ['taskId']); }
export function stfTaskStartsPath(params: Params<'taskId'>) { return process('/stf/tasks/' + params.taskId + '/starts', params, ['taskId']); }
export function stfTaskAssigneePath(params: Params<'taskId' | 'id'>) { return process('/stf/tasks/' + params.taskId + '/assignees/' + params.id + '', params, ['taskId','id']); }
export function stfTaskCommentsPath(params: Params<'taskId'>) { return process('/stf/tasks/' + params.taskId + '/comments', params, ['taskId']); }
export function stfTaskCommentPath(params: Params<'taskId' | 'id'>) { return process('/stf/tasks/' + params.taskId + '/comments/' + params.id + '', params, ['taskId','id']); }
export function copyFromTaskStfTaskRoutinesPath(params: Params<'taskId'>) { return process('/stf/tasks/' + params.taskId + '/routines/copy_from_task', params, ['taskId']); }
export function stfTasksPath(params?: Record<string, Value>) { return process('/stf/tasks', params, []); }
export function newStfTaskPath(params?: Record<string, Value>) { return process('/stf/tasks/new', params, []); }
export function editStfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '/edit', params, ['id']); }
export function stfTaskPath(params: Params<'id'>) { return process('/stf/tasks/' + params.id + '', params, ['id']); }
export function stfTimeTransfersPath(params?: Record<string, Value>) { return process('/stf/time_transfers', params, []); }
export function stfTimeTransferLogsPath(params?: Record<string, Value>) { return process('/stf/time_transfer_logs', params, []); }
export function newStfTimeTransferLogPath(params?: Record<string, Value>) { return process('/stf/time_transfer_logs/new', params, []); }
export function stfTaskQualityCheckPath(params: Params<'id'>) { return process('/stf/task_quality_checks/' + params.id + '', params, ['id']); }
export function nextClientUidStfCompaniesPath(params?: Record<string, Value>) { return process('/stf/companies/next_client_uid', params, []); }
export function stfCompanyRoboEnvPath(params: Params<'companyId'>) { return process('/stf/companies/' + params.companyId + '/robo_env', params, ['companyId']); }
export function stfCompanyUserAccountsPath(params: Params<'companyId'>) { return process('/stf/companies/' + params.companyId + '/user_accounts', params, ['companyId']); }
export function stfCompaniesPath(params?: Record<string, Value>) { return process('/stf/companies', params, []); }
export function newStfCompanyPath(params?: Record<string, Value>) { return process('/stf/companies/new', params, []); }
export function editStfCompanyPath(params: Params<'id'>) { return process('/stf/companies/' + params.id + '/edit', params, ['id']); }
export function stfCompanyPath(params: Params<'id'>) { return process('/stf/companies/' + params.id + '', params, ['id']); }
export function stfUserContractPlanPath(params: Params<'id'>) { return process('/stf/user_contract_plans/' + params.id + '', params, ['id']); }
export function monthDataStfUserPath(params: Params<'id'>) { return process('/stf/users/' + params.id + '/month_data', params, ['id']); }
export function customerSuccessStfUsersPath(params?: Record<string, Value>) { return process('/stf/users/customer_success', params, []); }
export function invitedStfUserUserRegistrationMailerPreviewsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration_mailer_previews/invited', params, ['userId']); }
export function thanksStfUserUserRegistrationMailerPreviewsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration_mailer_previews/thanks', params, ['userId']); }
export function completedStfUserUserRegistrationMailerPreviewsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration_mailer_previews/completed', params, ['userId']); }
export function rejectedStfUserUserRegistrationMailerPreviewsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration_mailer_previews/rejected', params, ['userId']); }
export function stfUserUserRegistrationMailerPreviewsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration_mailer_previews', params, ['userId']); }
export function summaryStfUserTimeTransferLogsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/time_transfer_logs/summary', params, ['userId']); }
export function stfUserTimeTransferLogsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/time_transfer_logs', params, ['userId']); }
export function stfUserReportsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/reports', params, ['userId']); }
export function newStfUserReportPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/reports/new', params, ['userId']); }
export function editStfUserReportPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/reports/' + params.id + '/edit', params, ['userId','id']); }
export function stfUserReportPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/reports/' + params.id + '', params, ['userId','id']); }
export function contractParamsStfUserUserContractsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_contracts/contract_params', params, ['userId']); }
export function sendMailStfUserUserContractPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.id + '/send_mail', params, ['userId','id']); }
export function contractChangedStfUserUserContractUserMailerPreviewsPath(params: Params<'userId' | 'userContractId'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.userContractId + '/user_mailer_previews/contract_changed', params, ['userId','userContractId']); }
export function stfUserUserContractUserMailerPreviewsPath(params: Params<'userId' | 'userContractId'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.userContractId + '/user_mailer_previews', params, ['userId','userContractId']); }
export function newStfUserUserContractUserMailerPreviewPath(params: Params<'userId' | 'userContractId'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.userContractId + '/user_mailer_previews/new', params, ['userId','userContractId']); }
export function editStfUserUserContractUserMailerPreviewPath(params: Params<'userId' | 'userContractId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.userContractId + '/user_mailer_previews/' + params.id + '/edit', params, ['userId','userContractId','id']); }
export function stfUserUserContractUserMailerPreviewPath(params: Params<'userId' | 'userContractId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.userContractId + '/user_mailer_previews/' + params.id + '', params, ['userId','userContractId','id']); }
export function stfUserUserContractsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_contracts', params, ['userId']); }
export function newStfUserUserContractPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_contracts/new', params, ['userId']); }
export function editStfUserUserContractPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.id + '/edit', params, ['userId','id']); }
export function stfUserUserContractPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_contracts/' + params.id + '', params, ['userId','id']); }
export function stfUserContractApprovalRequestPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/contract_approval_request', params, ['userId']); }
export function inviteStfUserUserRegistrationPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration/invite', params, ['userId']); }
export function approveBySalesStfUserUserRegistrationPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration/approve_by_sales', params, ['userId']); }
export function approveByCsStfUserUserRegistrationPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration/approve_by_cs', params, ['userId']); }
export function rejectStfUserUserRegistrationPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration/reject', params, ['userId']); }
export function completeStfUserUserRegistrationPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_registration/complete', params, ['userId']); }
export function stfUserUserAccountsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_accounts', params, ['userId']); }
export function newStfUserUserAccountPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/user_accounts/new', params, ['userId']); }
export function editStfUserUserAccountPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_accounts/' + params.id + '/edit', params, ['userId','id']); }
export function stfUserUserAccountPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_accounts/' + params.id + '', params, ['userId','id']); }
export function activateStfUserUserAccountMembershipPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_account_memberships/' + params.id + '/activate', params, ['userId','id']); }
export function stfUserUserAccountMembershipPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/user_account_memberships/' + params.id + '', params, ['userId','id']); }
export function clearErrorStfUserRoboPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/robo/clear_error', params, ['userId']); }
export function stfUserRoboPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/robo', params, ['userId']); }
export function stfUserRoboPaymentMethodsPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/robo_payment_methods', params, ['userId']); }
export function newStfUserRoboPaymentMethodPath(params: Params<'userId'>) { return process('/stf/users/' + params.userId + '/robo_payment_methods/new', params, ['userId']); }
export function stfUserRoboPaymentMethodPath(params: Params<'userId' | 'id'>) { return process('/stf/users/' + params.userId + '/robo_payment_methods/' + params.id + '', params, ['userId','id']); }
export function stfUsersPath(params?: Record<string, Value>) { return process('/stf/users', params, []); }
export function newStfUserPath(params?: Record<string, Value>) { return process('/stf/users/new', params, []); }
export function editStfUserPath(params: Params<'id'>) { return process('/stf/users/' + params.id + '/edit', params, ['id']); }
export function stfUserPath(params: Params<'id'>) { return process('/stf/users/' + params.id + '', params, ['id']); }
export function stfStaffShiftsPath(params?: Record<string, Value>) { return process('/stf/staff_shifts', params, []); }
export function tagsStfTaskCategoryPath(params: Params<'id'>) { return process('/stf/task_categories/' + params.id + '/tags', params, ['id']); }
export function relatedStfCommentsPath(params?: Record<string, Value>) { return process('/stf/comments/related', params, []); }
export function stfSubSkillCategorySkillPath(params: Params<'subSkillCategoryId'>) { return process('/stf/sub_skill_categories/' + params.subSkillCategoryId + '/skill', params, ['subSkillCategoryId']); }
export function stfStaffBillsPath(params?: Record<string, Value>) { return process('/stf/staff_bills', params, []); }
export function stfStaffBillPath(params: Params<'id'>) { return process('/stf/staff_bills/' + params.id + '', params, ['id']); }
