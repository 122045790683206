import * as React from 'react';
import Select from 'react-select';
import type { StylesConfig, OnChangeValue, ActionMeta } from 'react-select';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';
import { singleSelectCustomStyles } from '@/workforce/components/utils/ReactSelectUtils';
import type { SelectOption } from '@/workforce/components/utils/ReactSelectUtils';

type Props = LabelProps & {
  value?: string,
  name?: string | undefined,
  options: readonly SelectOption[],
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  required?: boolean,
  disabled?: boolean,
  onChange?: (value: string) => void,
}

type IsMulti = false;
const customStyles: StylesConfig<SelectOption, IsMulti> = {
  container: (provided, _state) => {
    return {
      ...provided,
      maxWidth: '500px',
    };
  },
  control: (provided, _state) => {
    return {
      ...provided,
      minHeight: '32px',
      fontSize: '13px',
    };
  },
  menu: (provided, _state) => {
    return {
      ...provided,
      fontSize: '13px',
      zIndex: 10,
    };
  },
  clearIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
  dropdownIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
};

const SearchSelectInput = (props: Props) => {
  const { label, id, required, options } = props;
  const currentValue = options.find((opt) => opt.value === props.value);
  const onChange = (value: OnChangeValue<SelectOption, IsMulti>, _: ActionMeta<SelectOption>) => props.onChange && props.onChange(value?.value ?? '');

  return (
    <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
      <Select
        id={props.id}
        name={props.name}
        isClearable
        isDisabled={props.disabled}
        styles={singleSelectCustomStyles}
        value={currentValue}
        onChange={onChange}
        options={options}
      />
    </FormGroup>
  );
}
export default SearchSelectInput;
