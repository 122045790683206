type QueryParamValueType = string | number | (string | number)[] | boolean | undefined;
type Options = {
  discardEmptyOrFalse?: boolean,
};

export function objectToRailsQueryString(obj: Record<string, QueryParamValueType>, options: Options = {}) {
  const { discardEmptyOrFalse } = options;
  return Object.entries(obj)
    .map(([key, value]) => {
      if (discardEmptyOrFalse && (value === false || value === '')) return;

      if (Array.isArray(value)) {
        if (value.length > 0)
          return value.map(val => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&');
      } else if (value != null) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .filter((item) => item != null)
    .join('&');
}
