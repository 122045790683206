$(document).on('change', '.js-skill-radio-button', async function () {
  const url = this.dataset.url;
  const level = this.value;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({ authenticity_token: $.rails.csrfToken(), level }),
  });
  const { flash } = await response.json();
  window.toastify.showNotice(flash);
});

$(document).on('click', '.js-change-availab', function () {
  $.ajax({
    type: 'PATCH',
    url: $(this).data('url'),
    dataType: 'script',
    data: {
      staff: {
        availability_status: $(this).data('availabilityStatus'),
        availability_immediate_at_time: $('#js-availability-immediate-at-time').val()
      }
    }
  });
});

$(document).on('click', '.js-staff-skills-introduction-edit-link', function () {
  $(this).hide();
  $('.js-staff-skills-introduction-edit-form').show();
});

function updateCorporateNameField() {
  const checked = document.querySelector('.js-corporate-name-enabled-radio:checked');
  const input = document.querySelector('.js-corporate-name-input');
  if (checked && input) {
    input.disabled = checked.value === '0'
  }
}
$(document).on('change', '.js-corporate-name-enabled-radio', updateCorporateNameField);
$(document).on('turbolinks:load', updateCorporateNameField)
