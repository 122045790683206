const formatSelect2Option = function(state) {
  if (!state.id) { return '未設定'; }
  const image_path = $(state.element).data('image');
  return $(`<img src='${image_path}' class='select2-option-icon'/><span class='select2-option-text'>${state.text}</span>`);
};

$(document).on('turbolinks:load', function() {
  $('.js-select2-chatwork-bot').select2({
    templateResult: formatSelect2Option,
    templateSelection: formatSelect2Option
  });
});

$(document).on('turbolinks:before-cache', function() {
  $('.js-select2-chatwork-bot').select2('destroy');
});
