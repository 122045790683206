const sendMoodText = input => $.ajax({
  url: '/stf/profile',
  dataType: 'json',
  method: 'PATCH',
  data: {
    staff: {
      mood_text: $(input).val()
    }
  },
  error: () => alert('状態メッセージ更新時にエラーが発生しました'),
});

$(document).on('turbolinks:load', function() {
  $('.js-mood-text-input').on('keydown', function(e) {
    if (e.code === 'Enter') {
      e.preventDefault();
      sendMoodText(this);
    }
  });

  return $('.js-mood-text-input').on('change', function(e) {
    sendMoodText(this);
  });
});
