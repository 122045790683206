import type { StylesConfig, OnChangeValue, MultiValue } from 'react-select';

export type SelectOption = {
  readonly value: string,
  readonly label: string,
}

export const singleSelectCustomStyles: StylesConfig<SelectOption, false> = {
  container: (provided, _state) => {
    return {
      ...provided,
      maxWidth: '500px',
    };
  },
  control: (provided, _state) => {
    return {
      ...provided,
      minHeight: '32px',
      fontSize: '13px',
    };
  },
  menu: (provided, _state) => {
    return {
      ...provided,
      fontSize: '13px',
      zIndex: 10,
    };
  },
  clearIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
  dropdownIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
};

export type MultiSelectValue = MultiValue<SelectOption>;
export const multiSelectCustomStyles: StylesConfig<SelectOption, true> = {
  // container: (provided, _state) => {
  //   return {
  //     ...provided,
  //     maxWidth: '500px',
  //   };
  // },
  control: (provided, _state) => {
    return {
      ...provided,
      minHeight: '32px',
      fontSize: '13px',
    };
  },
  menu: (provided, _state) => {
    return {
      ...provided,
      fontSize: '13px',
      zIndex: 10,
    };
  },
  clearIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
  dropdownIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '5px',
    };
  },
};
