import { rewrapE } from '@/lib/rewrap_with_error_boundary';

import NotificationsContainer from './containers/NotificationsContainer';
import UserContractForm from './components/user_contracts/UserContractForm';
import PricingSimulatorForm from './components/user_contracts/PricingSimulatorForm';
import StaffSearch from './components/staffs/StaffSearch';
import StaffSearchModal from './containers/StaffSearchModal';
import StaffSearchInput from './containers/StaffSearchInput';
import OfferButton from './containers/OfferButton';
import AssignButton from './containers/AssignButton';

rewrapE('x-notifications-container', NotificationsContainer, true);
rewrapE('x-user-contract-form', UserContractForm);
rewrapE('x-pricing-simulator-form', PricingSimulatorForm);
rewrapE('x-staff-search', StaffSearch);
rewrapE('x-staff-search-modal', StaffSearchModal);
rewrapE('x-staff-search-input', StaffSearchInput);
rewrapE('x-offer-button', OfferButton);
rewrapE('x-assign-button', AssignButton);
