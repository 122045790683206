import * as React from 'react';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';

type Props = LabelProps & {
  value: string,
  name?: string | undefined,
  wrapperClassName?: string | undefined,
  required?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  onChange?: (value: string) => void,
}

const yearOptions = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const result = []
  for( let i = currentYear - 5; i <= currentYear + 5; i++ ) {
    result.push(i);
  }
  return result;
}

const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const formatDate = (year: string, month: string) => `${year}-${month.padStart(2, '0')}-01`;

const useInnerState = (props: Props) => {
  const propsDate = new Date(props.value)
  const propsYear = propsDate.getFullYear().toString();
  const propsMonth = (propsDate.getMonth() + 1).toString();
  const [year, setYear] = React.useState(propsYear);
  const [month, setMonth] = React.useState(propsMonth);

  if (propsYear != year || propsMonth != month) {
    setYear(propsYear);
    setMonth(propsMonth);
  }

  const onChangeValues = (year: string, month: string) => {
    props.onChange && props.onChange(formatDate(year, month));
  }

  return { year, setYear, month, setMonth, onChangeValues };
}

const MonthInput = (props: Props) => {
  const { label, id, required } = props;
  const years = yearOptions();
  const { year, setYear, month, setMonth, onChangeValues } = useInnerState(props);

  const onChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setYear(newValue);
    onChangeValues(newValue, month);
  };
  const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setMonth(newValue);
    onChangeValues(year, newValue);
  };

  const date = formatDate(year, month);

  return <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
    <input id={props.id} type="hidden" name={props.name} value={date} />
    <div className="form-inline">
      <select value={year} onChange={onChangeYear} className="form-control" disabled={props.disabled}>
        { years.map((y) => <option key={y} value={y.toString()}>{`${y}年`}</option>) }
      </select>
      { " " }
      <select value={month} onChange={onChangeMonth} className="form-control" disabled={props.disabled}>
        { monthOptions.map((m) => <option key={m} value={m.toString()}>{`${m}月`}</option>) }
      </select>
    </div>
  </FormGroup>
}
export default MonthInput;
