import $ from 'jquery';

function onDisableCheckboxChanged(checkbox){
  const $checkbox = $(checkbox);
  const checked = $checkbox.is(':checked');
  const $scope = $checkbox.closest('.js-disable-checkbox-scope');
  if (checked) {
    $scope.find('.js-disable-checkbox').each((_, elem) => {
      if (elem != checkbox) {
        $(elem).prop('checked', false);
      }
    });
  }
  const $fields = $scope.find('.js-disable-checkbox-disabled-fields');
  $fields.toggle(!checked);
  $fields.find('input, select').prop('disabled', checked);
}

function updateDisableCheckboxFields() {
  $('.js-disable-checkbox:checked').each((_, elem) => {
    onDisableCheckboxChanged(elem);
  });
}

function updateSubmitButton() {
  $('.js-submit-registration').prop('disabled', !$('.js-accept-term-of-use-checkbox').is(':checked'))
}

$(document).on('turbolinks:load', () => {
  $('.js-confirm-back-link').on('click', (e) => {
    e.preventDefault();
    $('.js-confirm-back-form').trigger('submit');
  });

  $('.js-disable-checkbox').on('change', (e) => {
    onDisableCheckboxChanged(e.currentTarget);
  });

  $('.js-accept-term-of-use-checkbox').on('change', updateSubmitButton);

  updateDisableCheckboxFields();
  updateSubmitButton();
});
