import * as React from 'react';
import type { LabelProps } from './Label';

type Props = LabelProps & {
  value?: string,
  checked?: boolean,
  name?: string | undefined,
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  readOnly?: boolean,
  disabled?: boolean,
  min?: number | undefined,
  max?: number | undefined,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
}

const labelStyle = (disabled: boolean | undefined): React.CSSProperties => disabled ? ({ cursor: 'not-allowed', userSelect: 'none' }) : ({ userSelect: 'none' });

const CheckboxInput = (props: Props) => {
  return <div className="form-group">
    <div className="checkbox">
      <input type="hidden" value="0" autoComplete="off" name={props.name}></input>
      <label>
        <input
          type="checkbox"
          id={props.id}
          value="1"
          checked={props.checked}
          name={props.name}
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={props.onChange}
          style={labelStyle(props.disabled)}
        />
        <span style={labelStyle(props.disabled)}>{ props.label }</span>
      </label>
    </div>
  </div>;
}
export default CheckboxInput;
