import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { objectToRailsQueryString } from '@/lib/query';
import type { Staff } from '@/workforce/types/models';
import StaffSearchModal from './StaffSearchModal';
import type { OptionProps as StaffSearchProps } from '@/workforce/components/staffs/StaffSearch';
import type { SelectOption, MultiSelectValue } from '@/workforce/components/utils/ReactSelectUtils';
import { multiSelectCustomStyles } from '@/workforce/components/utils/ReactSelectUtils';
import polyglot from '@/lib/polyglot';

type Props = {
  inputName: string,
  staffs?: MultiSelectValue,
  showSchedule: boolean,
} & StaffSearchProps;

const promiseOptions = async (inputValue: string): Promise<SelectOption[]> => {
  const queries = objectToRailsQueryString({ name: inputValue })
  const response = await fetch(
    `/stf/staff_search?${queries}`,
    {
      method: 'GET',
      headers: {
      'Accept': 'application/json',
      },
    }
  );
  const staffs: Staff[] = await response.json();
  return staffs.map((staff) => ({ value: staff.id.toString(), label: staff.name }));
}

const StaffSearchInput = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [staffs, setStaffs] = useState<MultiSelectValue>(props.staffs ?? []);
  const [editingStaffs, setEditingStaffs] = useState<MultiSelectValue>([]);

  const onCancel = () => {
    setModalOpen(false);
  };
  const onOk = () => {
    setModalOpen(false);
    setStaffs([...staffs, ...editingStaffs]);
    setEditingStaffs([]);
1  }

  return (
    <div className='row'>
      <div className="col-md-8" data-test-id="staff-search-select">
        <AsyncSelect
          isMulti
          name={props.inputName}
          value={staffs}
          styles={multiSelectCustomStyles}
          cacheOptions
          defaultOptions
          onChange={(value: MultiSelectValue) => setStaffs(value)}
          loadOptions={promiseOptions}
        />
      </div>
      <div className="col-md-4">
        <button type="button" className='btn btn-info' onClick={() => setModalOpen(true)}>{polyglot.t('js.staff_search.find')}</button>
        <StaffSearchModal
          title={polyglot.t('js.staff_search.modal.title')}
          isOpen={isModalOpen}
          onOk={onOk}
          onCancel={onCancel}
          okLabel={polyglot.t('js.staff_search.modal.add')}
          showSchedule={props.showSchedule}
          teamOptions={props.teamOptions}
          dutyOptions={props.dutyOptions}
          skillOptions={props.skillOptions}
          excludingStaffIds={staffs.map((option) => parseInt(option.value))}
          selectedStaffs={editingStaffs}
          onChangeSelected={(value) => setEditingStaffs(value)}
          />
      </div>
    </div>
  );
}

export default StaffSearchInput;
