import $ from 'jquery';

$(document).on('click', '.js-user-attachment-button', function(e){
  e.preventDefault();
  $(e.currentTarget).closest('.nested-fields').find('.js-user-attachment-file-input').trigger('click');
});

$(document).on('change', '.js-user-attachment-file-input', function(e){
  const input = e.currentTarget;
  if (input.files && input.files[0]) {
    const $fieldWrapper = $(input).closest('.nested-fields');
    $fieldWrapper.find('.js-user-attachment-file-name-wrapper').show();
    $fieldWrapper.find('.js-user-attachment-file-name').text(input.files[0].name);
    $fieldWrapper.find('.js-user-attachment-button').hide();
    const $addLink = $('.js-add-attachment-fields');
    $addLink.trigger('click');
  }
});
