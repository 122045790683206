import { gaSkillEvent } from '@/lib/gaEvents';

const currentSkills = function(){
  const tokenInput = $('.js-working-timetables-token-input');
  if (tokenInput.length > 0) {
    const skills = tokenInput.tokenInput('get').map(hash => hash.name);
    return skills;
  } else {
    return [];
  }
};

const initializeTokenInput = function(){
  $('.js-working-timetables-skill-selector').select2({ dropdownParent: $('.js-select-2-parent') });
  $('.js-working-timetables-skill-level-selector').select2({ dropdownParent: $('.js-select-2-parent') });
  $('.js-working-timetables-token-input').tokenInput('', {
    theme: "workforce",
    tokenFormatter: (item) => `<li>${item.name}</li>`,
    onAdd: () => fetchWorkingTimetable({}),
    onDelete: () => fetchWorkingTimetable({}),
    hintText: null,
  }
  );
};

var fetchWorkingTimetable = function(params){
  let { startDate, duties, shifts } = params;
  const url = $('.js-working-timetables-data').data('url');
  const skills = currentSkills();
  if (startDate == null) { startDate = $('.js-working-timetables-data').data('start-date'); }
  if (duties == null) { duties = $('.js-working-timetables-data').data('duties'); }
  if (shifts == null) { shifts = $('.js-working-timetables-data').data('shifts'); }
  $('.js-working-timetables-table').html('');
  $('.js-loading-temporary-working-timetable').show();
  return $.ajax({
    type: 'GET',
    url,
    dataType: 'script',
    data: {
      start_date: startDate,
      duties,
      shifts,
      skills
    }}).then(() => initializeTokenInput());
};

$(document).on('click', '.js-working-timetables-duty-button', function(event){
  event.preventDefault();
  const duties = $('.js-working-timetables-data').data('duties');
  const clickedOption = $(this).data('duty');
  if (duties.includes(clickedOption)) {
    duties.splice(duties.indexOf(clickedOption), 1);
  } else {
    duties.push(clickedOption);
  }
  fetchWorkingTimetable({duties});
});

$(document).on('click', '.js-working-timetables-shift-button', function(event){
  event.preventDefault();
  const shifts = $('.js-working-timetables-data').data('shifts');
  const clickedOption = $(this).data('shift');
  if (shifts.includes(clickedOption)) {
    shifts.splice(shifts.indexOf(clickedOption), 1);
  } else {
    shifts.push(clickedOption);
  }
  fetchWorkingTimetable({shifts});
});

$(document).on('click', '.js-working-timetables-change-date-button', function(event){
  event.preventDefault();
  const startDate = $(this).data('start-date');
  fetchWorkingTimetable({startDate});
});

$(document).on('click', '.js-working-timetables-skill-selector-add', function(event){
  event.preventDefault();
  const skillName = $('.js-working-timetables-skill-selector').val();
  const skillLevel = $('.js-working-timetables-skill-level-selector').val();
  gaSkillEvent(skillName, skillLevel);
  const skill = [skillName, skillLevel].join(' | ');
  $('.js-working-timetables-token-input').tokenInput('add', { id: skill, name: skill });
});

$(document).on('click', '.js-working-timetables-skill-button', function(event){
  event.preventDefault();
  const skill = $(this).text();
  const [_, skillName, skillLevel] = /^(.+?)\s*\|\s*([^|]+)$/.exec(skill)
  gaSkillEvent(skillName, skillLevel);
  $('.js-working-timetables-token-input').tokenInput('add', { id: skill, name: skill });
});

$(document).on('turbolinks:load', function() {
  initializeTokenInput();
});
