import $ from 'jquery';

function setTeamName(teamName) {
  $('.js-front-team-name-input').val(teamName);
}

function updateTeamName() {
  const $select = $('.js-front-staff-select');
  const staffId = $select.val();
  const baseUrl = $select.data('base-url');
  if (staffId) {
    $.ajax({
      url: `${baseUrl}/${staffId}`,
      method: 'GET',
      dataType: 'json',
      success: (data, status, xhr) => {
        if (data && data['staff'] && data['staff']['team']) {
          const teamName = data['staff']['team']['name'];
          setTeamName(teamName);
        } else {
          setTeamName('');
        }
      },
      error: (xhr, status, errorThrown) => {
        setTeamName('');
      },
    });
  } else {
    setTeamName('');
  }
}

$(document).on('turbolinks:load', () => {
  $('.js-front-staff-select').on('change', updateTeamName);
});
