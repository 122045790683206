import $ from 'jquery';
import { debounce } from 'lodash-es';

$(document).on('turbolinks:load', () => {
  const $searchForm = $('.js-user-search-form');
  $searchForm.on('submit', (e) => e.preventDefault());

  const requestSearchUsers = () => {
    $('.js-users-loading-spinner').show();
    $.ajax({
      url: $searchForm.attr('action'),
      dataType: 'script',
      method: 'GET',
      data: $searchForm.serialize(),
    });
  };
  const debouncedRequestSearchUser = debounce(requestSearchUsers, 1000);

  $searchForm.find('input[type="text"]').each(({currentTarget}) => {
    const $currentTarget = $(currentTarget);
    $currentTarget.data('prev-value', $currentTarget.val());
  });

  $searchForm.find('input[type="text"]').on('keyup', ({currentTarget}) => {
    const $currentTarget = $(currentTarget);
    const value = $currentTarget.val();
    if ($currentTarget.data('prev-value') !== value) {
      $currentTarget.data('prev-value', value);
      debouncedRequestSearchUser();
    }
  });

  $searchForm.on('change', requestSearchUsers);
});
