import cable from '../../lib/cable';

let subscription = null;

function messageReceived(data) {
  if ($(`comment_${data.comment_id}`).length > 0) return;

  $.ajax({
    dataType: 'script',
    url: data.url
  });
}

$(document).on('turbolinks:load', function() {
  if (subscription != null) {
    subscription.unsubscribe();
    subscription = null;
  }
  const $subscription = $('.js-channel-subscription[data-channel="task_comments"]');
  if ($subscription.length === 0) return;

  subscription = cable.subscriptions.create({
    channel: "TaskCommentsChannel",
    task_id: $subscription.data('task-id')
  }, {
    received: messageReceived
  });
});
