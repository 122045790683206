import $ from 'jquery';

const selectSelector = '.js-free-text-select';
const scopeSelector = '.js-free-text-select-scope';
const freeTextFieldWrapperSelector = '.js-free-text-field-warapper';
const updateTargetFields = ($scope) => {
  const $select = $scope.find(selectSelector);
  const $freeTextFieldWrapper = $scope.find(freeTextFieldWrapperSelector);
  const enabled =  $select.data('free-text-enabled').indexOf(parseInt($select.val())) >= 0;
  $freeTextFieldWrapper.toggle(enabled);
  $freeTextFieldWrapper.find('input').prop('disabled', !enabled);
};

$(document).on('change', selectSelector, (e) => {
  updateTargetFields($(e.currentTarget).closest(scopeSelector));
});

$(document).on('turbolinks:load', () => {
  $(scopeSelector).each((_, elem) => {
    updateTargetFields($(elem));
  });
});
