import Tribute from "tributejs";
import { onConnect } from '@sonicgarden/onconnect';

function nameWithSan(name: string): string {
  return `${name} さん`;
}
function identity(str: string) {
  return str;
}
const decorateName = window.APP.language === 'ja' ? nameWithSan : identity;

onConnect('.js-mentionable', (elem) => {
  const dataSetElem = document.querySelector<HTMLElement>('.js-staff-mention-names');
  if (dataSetElem == null) return;
  const staffNameJson = dataSetElem.dataset.staffs
  if (staffNameJson == null) return;
  const staffNames = JSON.parse(staffNameJson) as string[];
  const values = staffNames.map((name) => {
    return { key: name, value: decorateName(name) };
  });
  const tribute = new Tribute({
    values
  });
  tribute.attach(elem);
  return () => tribute.detach(elem);
});
