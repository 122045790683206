import $ from 'jquery';

const checkBoxSelector = '.js-collapse-fields-checkbox';
const scopeSelector = '.js-collapse-fields-scope';
const updateTargetFields = ($scope) => {
  const hidden = $scope.find(checkBoxSelector + ':checked').length > 0;
  $scope.find('.js-collapse-fields-target').toggle(!hidden);
};

$(document).on('change', checkBoxSelector, (e) => {
  updateTargetFields($(e.currentTarget).closest(scopeSelector));
});

$(document).on('turbolinks:load', () => {
  $(scopeSelector).each((_, elem) => {
    updateTargetFields($(elem));
  });
});
