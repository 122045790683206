type DeadlineType = 'relative_days' | 'day_of_month' | 'last_day' | 'business_day_of_month' | 'last_business_day';

const daysRequiredTypes: DeadlineType[] = [
  'relative_days',
  'day_of_month',
  'business_day_of_month',
];

const dayMinMax: Record<DeadlineType, number[]> = {
  'relative_days': [0, 30],
  'day_of_month': [1, 31],
  'business_day_of_month': [1, 23],
  // 以下は使わないけどTypeScriptに怒られないように定義している
  'last_day': [0, 0],
  'last_business_day': [0, 0],
}

function onChangeDeadlineType(selectElem: HTMLSelectElement) {
  const daysElem = document.querySelector<HTMLInputElement>('.js-routine-deadline-days');
  if (daysElem == null) return;

  const deadlineType = selectElem.value as DeadlineType;
  const disabled = (daysRequiredTypes.indexOf(deadlineType) < 0);
  daysElem.disabled = disabled;
  if (disabled) return;

  const [min, max] = dayMinMax[deadlineType];
  daysElem.min = min.toString();
  daysElem.max = max.toString();
}
document.addEventListener('turbolinks:load', () => {
  const selectElem = document.querySelector<HTMLSelectElement>('.js-routine-deadline-type');
  const daysElem = document.querySelector<HTMLInputElement>('.js-routine-deadline-days');
  if (selectElem == null || daysElem == null) return;

  selectElem.addEventListener('change', () => onChangeDeadlineType(selectElem));
  onChangeDeadlineType(selectElem);
});
