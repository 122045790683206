import Toastify from 'toastify-js'

export function showNotice(message: string) {
  Toastify({
    text: message,
    className: 'success',
    duration: 5000,
    close: true,
  }).showToast();
}

export function showAlert(message: string) {
  Toastify({
    text: message,
    className: 'danger',
    duration: -1,
    close: true,
  }).showToast();
}

// まだ js.erb から使うことがあるので window からも呼び出せるようにする
window.toastify = {
  showNotice,
  showAlert,
};
