import * as React from 'react'
import { formatMinutes } from '../../../lib/formatMinutes';
import { Comment } from '../../models/Comment';
import { formatIsoDateTimeString } from '../../../lib/datetimeUtils';

type Props = {
  comment: Comment,
};

type PartialLabelProps = Pick<Comment, 'workingMinutes' | 'commentTypeText'>;
type LabelProps = Required<PartialLabelProps>;

function isLabelProps(data: PartialLabelProps): data is LabelProps {
  return (data.workingMinutes != null && data.commentTypeText != null);
}

const WorkingMinutesLabel = (props: { data: LabelProps}) => {
  const { workingMinutes, commentTypeText } = props.data;
  return (<span className="label label-info">{`${formatMinutes(workingMinutes)} : ${commentTypeText}`}</span>);
};

const NotificationComment = (props: Props) => {
  const { comment } = props;
  return (<div className="notification-comment">
    <div className="notification-comment__left">
      <img src={comment.staff.avatarUrl} className="notification-comment__avatar" />
    </div>
    <div className="notification-comment__right">
      <span className="notification-comment__staff-name">{comment.staff.name}</span>
      <span className="notification-comment__time">{formatIsoDateTimeString(comment.createdAt, window.APP.timeZone)}</span>
      { isLabelProps(comment) && comment.workingMinutes > 0 && <WorkingMinutesLabel data={comment} />}
      <div className="notification-comment__content" dangerouslySetInnerHTML={ {__html: comment.contentHtml} } />
    </div>
  </div>);
};

export default NotificationComment;
