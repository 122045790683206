import * as React from 'react'
import { useState, useEffect } from 'react'

type Props = {
  title: string,
  isOpen: boolean,
  onCancel?: () => void,
  children: React.ReactNode,
  footer?: React.ReactNode,
};

function useEscKey(handler: () => void) {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handler();
      }
    }
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });
}

const nopFunc = () => {};

const Modal = (props: Props) => {
  const { isOpen, footer } = props;
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const onCancel = props.onCancel ?? nopFunc;
  let backdrop: HTMLDivElement | undefined = undefined;

  useEscKey(onCancel);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    const bodyElement = document.querySelector('body')!;
    if (isModalOpen) {
      bodyElement.classList.add('mini-modal-open');
      backdrop = document.createElement('div');
      backdrop.classList.add('mini-modal-backdrop', 'mini-modal-show');
      bodyElement.appendChild(backdrop);
    } else {
      bodyElement.classList.remove('mini-modal-open');
      document.querySelector('.mini-modal-backdrop')?.remove();
    }
    return () => {
      if (backdrop) {
        backdrop.remove();
        backdrop = undefined;
      }
    };
  }, [isModalOpen]);

  const modalClasses = ['mini-modal'];
  if (isModalOpen) {
    modalClasses.push('show');
  }
  return (<div className={modalClasses.join(' ')} onClick={() => onCancel()}>
    <div className="mini-modal-dialog" onClick={(e) => e.stopPropagation()}>
      <div className="mini-modal-content">
        <div className="mini-modal-header">
          <h2>{props.title}</h2>
          <button aria-label="Close" className="mini-modal-close" type="button" onClick={() => onCancel()}>×</button>
        </div>
        <div className="mini-modal-body fit-to-screen-height">
          {props.children}
        </div>
        {footer ? (<div className="mini-modal-footer justify-content-center">{footer}</div>) : null}
      </div>
    </div>
  </div>);
}

export default Modal;
