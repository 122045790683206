import polyglot from '@/lib/polyglot';

function initUserSelect2() {
  $('.js-user-select2').each((_, elem) => {
    const $elem = $(elem);
    const parentSelector = $elem.data('select2-parent') || '.overlay-ui-parent';
    $elem.select2({
      dropdownParent: $(parentSelector),
      placeholder: '',
      allowClear: true,
      ajax: {
        url: '/stf/users',
        dataType: 'json',
        delay: 300,
        data: function (params) {
          return {
            q: {
              name_cont: params.term,
            },
            page: params.page,
          };
        },
      }
    });
  });
}

function initSelect2() {
  $('.js-select2').each(function (_, elem) {
    const $elem = $(elem);
    const parentSelector = $elem.data('select2-parent') || '.overlay-ui-parent';
    $elem.select2({
      dropdownParent: $(parentSelector)
    });
  });
}

App.initSelect2 = () => {
  initSelect2();
  initUserSelect2();
};

// *.js.erb でDOMを動的に生成した後で呼ばれる初期化処理
App.commentInputs = function () {
  App.initSelect2();
  // NOTE: 以下の関数のページロード直後の呼び出しは、関数が定義されているあたりでやっている
  App.initFileInputs();
  App.initFileDropTarget();
};


$(document).on('turbolinks:load', function () {
  App.initSelect2();
});

$(document).on('turbolinks:before-cache', function () {
  $('.js-select2, js-user-select2').select2('destroy');
});
