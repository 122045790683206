import * as React from 'react';
import { useState, useRef } from 'react';
import $ from 'jquery';
import StaffSearchModal from './StaffSearchModal';
import type { OptionProps as StaffSearchProps } from '@/workforce/components/staffs/StaffSearch';
import type { MultiSelectValue } from '@/workforce/components/utils/ReactSelectUtils';
import polyglot from '@/lib/polyglot';

type Props = {
  actionUrl: string,
  showSchedule: boolean,
  excludingStaffIds: number[],
  commentedStaffIds: number[],
} & StaffSearchProps;

const OfferButton = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingStaffs, setEditingStaffs] = useState<MultiSelectValue>([]);
  const [sending, setSending] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const onCancel = () => {
    setModalOpen(false);
  };
  const onOk = () => {
    setSending(true);
    formRef.current?.submit();
  }

  return (
    <div className="d-inline-block">
      <button
        type="button"
        className="btn btn-success"
        onClick={() => setModalOpen(true)}>
        {polyglot.t('js.staff_search.add_offers')}
      </button>
      <form ref={formRef} action={props.actionUrl} method="post">
        <input type="hidden" name="_method" value="patch" />
        <input type="hidden" name="authenticity_token" value={$.rails.csrfToken()} autoComplete="off"></input>
        {
          editingStaffs.map((option) => <input key={option.value} type="hidden" name="task[assigning_staff_ids][]" value={option.value}/>)
        }
      </form>
      <StaffSearchModal
        isOpen={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        title={polyglot.t('js.staff_search.modal.title_add_offers')}
        okLabel={polyglot.t('js.staff_search.modal.add')}
        showSchedule={props.showSchedule}
        teamOptions={props.teamOptions}
        dutyOptions={props.dutyOptions}
        skillOptions={props.skillOptions}
        excludingStaffIds={props.excludingStaffIds}
        commentedStaffIds={props.commentedStaffIds}
        selectedStaffs={editingStaffs}
        onChangeSelected={(value) => setEditingStaffs(value)}
        disabled={sending}
      />
    </div>
  );
}

export default OfferButton;
