import * as React from 'react';
import Label from './Label';
import type { LabelProps } from './Label';

type Props = LabelProps & {
  className?: string | undefined,
  children?: React.ReactNode,
};

const FormGroup = (props: Props) => {
  const { label, id, required } = props;
  return <div className={`form-group ${props.className ?? ''}`}>
    <Label {...{ label, id, required }} />
    { props.children }
  </div>;
};

export default FormGroup;
