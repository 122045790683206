document.addEventListener('turbolinks:load', () => {
  const residence = document.querySelector('.js-residence-field');
  if (!residence ) return;

  residence.addEventListener('change', (e) => {
    const checkBox = document.querySelector('.js-withholding-tax-enabled-field');
    if (checkBox) {
      checkBox.checked = e.currentTarget.value === 'japan';
    }
  });
});
