import * as React from 'react'

type TabId = 'inbox' | 'working_minutes' | 'read';

export type Tab = {
  name: string,
  tabId: TabId,
  url: string,
  count?: number,
  active: boolean,
};

type Props = {
  tabs: Tab[];
};

const tabCount = (count?: number) => count != null ? <span>({count})</span> : null;

const NotificationTab = (props: { tab: Tab }) => {
  const { tab } = props;
  return (<li className={tab.active ? 'active' : ''}>
    <a href={tab.url}>{tab.name} {tabCount(tab.count)}</a>
  </li>);
}

const NotificationTabs = (props: Props) => {
  const { tabs } = props;

  return (<ul className="nav nav-tabs">
    {tabs.map(tab => <NotificationTab key={tab.tabId} tab={tab} />)}
  </ul>)
}
export default NotificationTabs;
