document.addEventListener('turbolinks:load', () => {
  const moreLink = document.querySelector('.js-more-related-comments');
  if (moreLink == null) return;

  moreLink.addEventListener('click', (e) => {
    const comentElements = document.querySelectorAll('.js-related-comments-comment');
    if (comentElements.length === 0) return;

    const lastElement = comentElements[comentElements.length - 1];
    const url = `${e.currentTarget.dataset.relatedCommentsUrl}?less_than_id=${lastElement.dataset.commentId}`;
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url,
    });
  });
});
