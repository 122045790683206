function isGtagAvailable(): boolean {
  return window.hasOwnProperty('gtag');
}

export function gaSkillEvent(skillName: string, skillLevel: string) {
  if (!isGtagAvailable()) return;

  gtag('event', 'skill_select', {
    'skill_name': skillName,
    'skill_level': skillLevel,
  });
}
