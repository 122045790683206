import * as React from 'react'
import NotificationTabs from './NotificationTabs';
import NotifiationList from './NotificationList';
import { Notification } from '../../models/Notification';
import polyglot from '@/lib/polyglot';
import type { Tab } from './NotificationTabs';

type Props = {
  tabs: Tab[],
  activeTabId: Tab['tabId'],
  notifications: Notification[],
  onReadAll?: () => void,
  onAction?: (notificationId: number) => void,
  children?: React.ReactNode,
};

const EmptyState = (props: { text: string })  => {
  return (<div className="empty-state-indicator">
    <i className="fas fa-check-circle" /> {props.text}
  </div>);
};

const ReadAllButton = (props: { onClick?: () => void }) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick();
    }
  };
  return <div className="my-4"><a href="#" className="btn btn-default" onClick={onClick}>{polyglot.t('js.notifications.mark_all_as_read')}</a></div>;
}

const NotificationsView = (props: Props) => {
  const readAllEnabled = props.notifications.length > 0 && props.activeTabId !== 'read';
  const emptyMessage = props.activeTabId === 'read' ? polyglot.t('js.notifications.no_read_notifications') : polyglot.t('js.notifications.no_unread_notifications');

  return (
    <div>
      <NotificationTabs tabs={props.tabs} />
      { readAllEnabled && <ReadAllButton onClick={props.onReadAll} /> }
      {
        props.notifications.length > 0 ?
        <NotifiationList notifications={props.notifications} onAction={props.onAction} /> :
        <EmptyState text={emptyMessage} />
      }
      { props.children }
    </div>
  );
};
export default NotificationsView;
