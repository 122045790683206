import { onConnect } from '@sonicgarden/onconnect';
import { showNotice } from '@/lib/toastify';
import polyglot from '@/lib/polyglot';
import $ from 'jquery';

type CommentType = 'comment' | 'time';

function changeCommentType(commentType: string) {
  const hidden = commentType === 'comment';
  document.querySelectorAll<HTMLElement>('.js-comment-type-time-input-wrapper').forEach((wrapper: HTMLElement) => {
    wrapper.hidden = hidden;
  })
  document.querySelectorAll<HTMLInputElement | HTMLSelectElement>('.js-comment-type-time-input').forEach((input) => input.disabled = hidden )
}

onConnect('.js-comment-type-radios', (element) => {
  const radios: HTMLInputElement[] = Array.from(element.querySelectorAll('input[type=radio]'));
  const onChangeRadio = () => {
    const checkedRadio = radios.filter((radio) => radio.checked)[0];
    if (checkedRadio) {
      changeCommentType(checkedRadio.value);
    }
  };
  radios.forEach((radio) => {
    radio.addEventListener('change', onChangeRadio);
  })
  onChangeRadio();

  return () => {
    radios.forEach((radio) => radio.removeEventListener('change', onChangeRadio));
  }
});

function showCommentFormAlert(message: string) {
  const alertElem = document.querySelector<HTMLElement>('.js-comment-form-alert');
  if (alertElem == null) return;

  alertElem.textContent = message;
  alertElem.style.display = 'block';
}

function clearCommentFormAlert() {
  const alertElem = document.querySelector<HTMLElement>('.js-comment-form-alert');
  if (alertElem == null) return;
  alertElem.style.display = 'none';
}

function resetForm(form: HTMLFormElement) {
  form.reset();
  // form.reset() だけだと select2 の状態がリセットされないので change イベントを select2 に送る
  $('.js-select2').trigger('change');
}

function unknownErrorMessage() {
  return polyglot.t('js.unknown_error');
}

async function sendComment(form: HTMLFormElement) {
  const formData = new FormData(form);

  try {
    const response = await fetch(form.action, {
      method: 'post',
      body: formData,
    });
    const contentType = response.headers.get('Content-Type');
    if (contentType?.startsWith('application/json')) {
      if (response.ok) {
        const json = await response.json();
        clearCommentFormAlert();
        showNotice(json.message);
        resetForm(form);
      } else {
        const json = await response.json();
        showCommentFormAlert(json.message || unknownErrorMessage());
      }
    } else {
      showCommentFormAlert(unknownErrorMessage());
    }
  } catch(error) {
    window.bugsnagClient?.notify(error as Error);
    showCommentFormAlert(unknownErrorMessage());
  }
  $.rails.enableFormElements($(form));
}

onConnect('.js-comment-form', (elem) => {
  const form = elem as HTMLFormElement;
  const listener = (e: SubmitEvent) => {
    e.preventDefault();
    sendComment(form);
  };
  elem.addEventListener('submit', listener);
  return () => elem.removeEventListener('submit', listener);
});
