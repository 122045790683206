import * as React from 'react';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';

type Option = {
  value: string,
  text: string,
};

type Props = LabelProps & {
  value?: string,
  name?: string | undefined,
  options: Option[],
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  required?: boolean,
  disabled?: boolean,
  onChange?: (value: string) => void,
}

const SelectInput = (props: Props) => {
  const { label, id, required } = props;
  return (
    <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
      <select
        id={props.id}
        name={props.name}
        className='form-control width-auto'
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        required={props.required}
        disabled={props.disabled}
      >
        <option key="_blank" value=""></option>
        { props.options.map((opt) => <option key={opt.value} value={opt.value}>{opt.text}</option>) }
      </select>
    </FormGroup>
  );
}
export default SelectInput;
