import { onConnect } from '@sonicgarden/onconnect';

function addMention(text: string, mentionAlias: string): string {
  const originalText = text === '' ? '' : `${text}\n`;
  const mention = window.APP.language === 'ja' ? `@${mentionAlias} さん\n` : `@${mentionAlias}\n`
  return originalText + mention;
}

onConnect('.js-comment-reply', (elem) => {
  elem.addEventListener('click', (e) => {
    e.preventDefault();
    const textarea = document.querySelector<HTMLTextAreaElement>('.js-comment-textarea');
    const replyLink = e.currentTarget as HTMLElement;
    if (textarea == null || replyLink == null) return;
    const mentionAlias = replyLink.dataset.mentionAlias;
    if (mentionAlias == null) return;

    textarea.value = addMention(textarea.value, mentionAlias);
    textarea.focus();
    textarea.dispatchEvent(new Event('autosize:update'));
  })
});
