import $ from 'jquery';

export function copyElementDataToClipboard(element: HTMLElement) {
    // NOTE: https または localhost でないと navigator.clipboard にアクセスできない
    // https://stackoverflow.com/a/51823007
    // ただし Chrome の以下のフラグを Enabled にして、 http://workforce.workforce.test のように入力すれば http でも使える
    // chrome://flags/#unsafely-treat-insecure-origin-as-secure
  if (navigator.clipboard == null) return;

  const $element = $(element);
  navigator.clipboard.writeText($element.data('clipboard-text'));
  $element.tooltip({placement: 'auto top', container: '#wrapper', title: 'コピーしました'});
  $element.tooltip('show');
  setTimeout(() => $element.tooltip('hide'), 1000);
}
