import * as React from 'react';
import $ from 'jquery';
import DateInput from '../form/DateInput';
import NumberInput from '../form/NumberInput';
import HoursMinsInput from '../form/HoursMinsInput';
import TextInput from '../form/TextInput';
import CheckboxInput from '../form/CheckboxInput';
import MonthInput from '../form/MonthInput';
import RadioGroupInput from '../form/RadioGroupInput';
import SearchSelectInput from '../form/SearchSelectInput';
import SelectInput from '../form/SelectInput';
import TextAreaInput from '../form/TextAreaInput';
import { strToNumber } from '../../../lib/math';
import { fetchPlan } from '../../api/user_contract_plans';
import { fetchContractParams } from '../../api/user_contracts';
import type {
  BillingTimingForForm,
  PaymentMethod,
  PaymentDueType,
  BillSendingTiming
} from '../../models/UserContract';
import {
  billingTimingForFormOptions,
  paymentMethodOptions,
  paymentDueTypeOptions,
  billSendingTimingOptions,
  dueDayTable,
  paymentDueDayOptions
} from '../../models/UserContract';
import polyglot from '@/lib/polyglot';

type SavedState = {
  userContractId: number | undefined,
  userContractPlanId: number | undefined,
  months: number | undefined,
  beginDate: string | undefined,
  endDate: string | undefined,
  firstRenewalDate: string | undefined,
  renewalDate: string | undefined,
  cancellationLimitDate: string | undefined,
  allottedMinutes: number,
  firstMonthAllottedMinutes: number,
  feePerHour: number | undefined,
  overtimeFee: number | undefined,
  monthlyFee: number | undefined,
  monthlyFeeAutoCalculation: boolean,
  overtimeDiscountEndDate: string,
  noBilling: boolean,
  irregular: boolean,
  billingTimingForForm: BillingTimingForForm,
  paymentMethod: PaymentMethod,
  paymentDueType: PaymentDueType,
  paymentDueDay: string,
  billSendingTiming: BillSendingTiming,
  memo: string,
};

type Props = SavedState & {
  actionUrl: string,
  userContractPlans: { id: number, name: string }[],
  periodType: string | undefined,
  userId: number,
  editDisabled: boolean,
  hasBaseFeeBillsAfterEndDate: boolean,
  showCreditWarning: boolean,
};

type State = SavedState & {
  periodType: string | undefined,
  firstMonthAllottedMinutesAutoCalculation: boolean,
};

type AutoCalcParamsSource = {
  userContractId: number | undefined,
  userContractPlanId: number | undefined,
  periodType: string | undefined,
  beginDate: string | undefined,
  allottedMinutes: number,
  firstRenewalDate: string | undefined,
  months: number | undefined,
  firstMonthAllottedMinutesAutoCalculation: boolean,
};

type ChangedData = 'periodType' | 'beginDate' | 'allottedMinutes' | 'firstMonthAllottedMinutesAutoCalculation' | 'other';

const updateMonthlyFee = (state: State) => {
  if (state.monthlyFeeAutoCalculation && state.feePerHour)
    return { ...state, monthlyFee: Math.floor(state.feePerHour * state.allottedMinutes / 60) };
  else
    return state;
}

const UserContractForm = (props: Props) => {
  const planRequestCounter = React.useRef(0);
  const paramsRequestCounter = React.useRef(0);
  const [state, setState] = React.useState<State>({ ...props, firstMonthAllottedMinutesAutoCalculation: props.userContractId == null });
  const isDaysPlan = state.periodType === 'days';

  const updateAutoCalcParams = async (
    { userContractId, userContractPlanId, periodType, beginDate, allottedMinutes, firstRenewalDate, months, firstMonthAllottedMinutesAutoCalculation }: AutoCalcParamsSource,
    changedData: ChangedData = 'other') => {
    if (userContractPlanId == null || beginDate == null) {
      setState((state) => ({
        ...state,
        renewalDate: undefined,
        cancellationLimitDate: undefined,
      }));
      return;
    }

    const { userId } = props;
    paramsRequestCounter.current += 1;
    const requestCounterValue = paramsRequestCounter.current
    const contractParams = await fetchContractParams({ userId, userContractId, userContractPlanId, beginDate, allottedMinutes, firstRenewalDate, months });
    if (contractParams == null) return;
    // 最新のリクエストのレスポンス以外は無視する
    if (paramsRequestCounter.current !== requestCounterValue) return;

    // 時契約開始日や次契約解約申し入れ期限日は保存しない表示用の値なので、毎回再計算してしまってもよい
    setState((state) => ({
      ...state,
      renewalDate: contractParams.renewalDate,
      cancellationLimitDate: contractParams.cancellationLimitDate,
    }));

    // 初月契約時間は自動計算なら毎回更新
    if (firstMonthAllottedMinutesAutoCalculation && (changedData === 'firstMonthAllottedMinutesAutoCalculation' || changedData === 'allottedMinutes' || changedData === 'beginDate')) {
      setState((state) => ({ ...state, firstMonthAllottedMinutes: contractParams.firstMonthAllottedMinutes }));
    }

    // トライアルプラン(periodType === 'days') なら開始日に依存して終了日を決定
    if (changedData === 'periodType' || (periodType === 'days' &&  changedData === 'beginDate')) {
      setState((state) => ({ ...state, endDate: contractParams.endDate }));
    }

    // 超過割引終了月は、プランの種類と開始日に依存している
    if (changedData === 'periodType' || changedData === 'beginDate') {
      setState((state) => ({ ...state, overtimeDiscountEndDate: contractParams.overtimeDiscountEndDate }));
    }
  }

  const onPlanChanged = async (value: string) => {
    const userContractPlanId = strToNumber(value, undefined)
    setState((state) => ({ ...state, userContractPlanId }));
    if (userContractPlanId == null) return;

    planRequestCounter.current += 1;
    const requestCounterValue = planRequestCounter.current;
    const plan = await fetchPlan(userContractPlanId);
    if (plan == null) return;
    // 最新のリクエストのレスポンス以外は無視する
    if (planRequestCounter.current !== requestCounterValue) return;

    const months = plan.periodType === 'days' ? undefined : plan.months;
    const periodTypeChanged = (state.periodType !== plan.periodType);
    const { periodType, feePerHour, overtimeFee } = plan;

    setState((state) => {
      const newState = {
        ...state,
        periodType,
        feePerHour,
        overtimeFee,
        months,
      }
      return updateMonthlyFee(newState);
    });
    await updateAutoCalcParams({ ...state, userContractPlanId, periodType, months}, periodTypeChanged ? 'periodType' : 'other');
  };

  const onChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    const paymentDueDay = (dueDayTable[paymentMethod] ?? 31).toString();
    setState((state) => ({ ...state, paymentMethod, paymentDueDay }));
  }

  return (
    <React.StrictMode>
      <form className='mb-5' action={props.actionUrl} method="post" data-test-id="user-contract-form">
        <input type="hidden" name="_method" value={props.userContractId != null ? 'patch' : 'post'} autoComplete="off"></input>
        <input type="hidden" name="authenticity_token" value={$.rails.csrfToken()} autoComplete="off"></input>
        {
          props.hasBaseFeeBillsAfterEndDate &&
          <div className="alert alert-danger d-flex justify-content-between align-items-center">
            <div>
              { polyglot.t('stf.user_contracts.form.base_fee_bills_after_end_date') }
            </div>
            <button
              name="ignore_end_date_warning"
              className="btn btn-danger"
              data-disable-with="送信中..."
            >
              警告を無視して更新
            </button>
          </div>
        }
        <SearchSelectInput
          label="契約プラン"
          id="user_contract_user_contract_plan_id"
          name="user_contract[user_contract_plan_id]"
          value={state.userContractPlanId?.toString() || ''}
          options={props.userContractPlans.map((plan) => ({ value: plan.id.toString(), label: plan.name }))}
          onChange={(value: string) => onPlanChanged(value)}
          required
          disabled={props.editDisabled}
        />
        {
          !isDaysPlan &&
          <NumberInput
            label="契約期間（月）"
            name="user_contract[months]"
            value={state.months}
            min={1}
            className="width-auto"
            onChange={
              (e) => {
                const months = strToNumber(e.target.value, undefined);
                setState((state) => ({ ...state, months }));
                updateAutoCalcParams({ ...state, months });
              }
            }
            required={true}
            disabled={props.editDisabled}
          />
        }
        <div className="d-flex">
          <DateInput
            label="開始日"
            id="user_contract_begin_date"
            name="user_contract[begin_date]"
            value={state.beginDate}
            required
            className="width-auto"
            onChange={
              (e) => {
                const beginDate = e.target.value;
                setState((state) => ({ ...state, beginDate }));
                updateAutoCalcParams({ ...state, beginDate }, 'beginDate');
              }
            }
            disabled={props.editDisabled}
          />
          <DateInput
            label="終了日"
            id="user_contract_end_date"
            name="user_contract[end_date]"
            value={state.endDate}
            className="width-auto"
            wrapperClassName="ml-4"
            onChange={(e) => setState((state) => ({ ...state, endDate: e.target.value}))}
          />
        </div>
        <div className="d-flex">
          <DateInput
            label="初回_次契約開始日"
            id="user_contract_first_renewal_date"
            name="user_contract[first_renewal_date]"
            value={state.firstRenewalDate}
            className="width-auto"
            onChange={
              (e) => {
                const firstRenewalDate = e.target.value
                setState((state) => ({ ...state, firstRenewalDate }));
                updateAutoCalcParams({ ...state, firstRenewalDate });
              }
            }
            disabled={props.editDisabled}
          />
          <TextInput label="次契約開始日" id="user_contract_renewal_date" value={state.renewalDate} disabled className="width-auto" wrapperClassName="ml-4"></TextInput>
          <TextInput label="次契約解約申し入れ期限日" id="user_contract_cancellation_limit_date" value={state.cancellationLimitDate} disabled className="width-auto" wrapperClassName="ml-4"></TextInput>
        </div>
        <div className="d-flex">
          <HoursMinsInput
            label="契約時間"
            id="user_contract_allotted_minutes"
            value={state.allottedMinutes}
            required
            name="user_contract[allotted_minutes]"
            onChange={
              (allottedMinutes: number) => {
                setState((state) => (updateMonthlyFee({ ...state, allottedMinutes })));
                updateAutoCalcParams({ ...state, allottedMinutes }, 'allottedMinutes');
              }
            }
            disabled={props.editDisabled}
          />
          {
            !isDaysPlan &&
            <HoursMinsInput
              label="初月契約時間"
              id="user_contract_first_month_allotted_minutes"
              value={state.firstMonthAllottedMinutes}
              name="user_contract[first_month_allotted_minutes]"
              wrapperClassName="ml-5"
              required={true}
              disabled={props.editDisabled}
              readOnly={state.firstMonthAllottedMinutesAutoCalculation}
              onChange={
                (firstMonthAllottedMinutes: number) => setState((state) => ({ ...state, firstMonthAllottedMinutes }))
              }
            />
          }
        </div>
        {
          !isDaysPlan &&
          <CheckboxInput
            label="初月契約時間を自動計算する"
            checked={state.firstMonthAllottedMinutesAutoCalculation}
            onChange={
              (e) => {
                const firstMonthAllottedMinutesAutoCalculation = e.target.checked;
                setState({ ...state, firstMonthAllottedMinutesAutoCalculation });
                updateAutoCalcParams({ ...state, firstMonthAllottedMinutesAutoCalculation }, 'firstMonthAllottedMinutesAutoCalculation');
              }
            }
            disabled={props.editDisabled}
          />
        }
        <div className="d-flex">
          <NumberInput
            label="通常単価（円/時間）"
            id="user_contract_fee_per_hour"
            name="user_contract[fee_per_hour]"
            value={state.feePerHour}
            min={0}
            className="width-auto"
            required
            readOnly
            disabled={props.editDisabled}
          />
          <NumberInput
            label="超過時間単価（円/時間）"
            id="user_contract_overtime_fee"
            name="user_contract[overtime_fee]"
            value={state.overtimeFee}
            min={0}
            className="width-auto"
            wrapperClassName="ml-4"
            required
            readOnly
            disabled={props.editDisabled}
          />
        </div>
        <NumberInput
          label="月額"
          id="user_contract_monthly_fee"
          name="user_contract[monthly_fee]"
          value={state.monthlyFee}
          required
          min={0}
          className="width-auto"
          readOnly={state.monthlyFeeAutoCalculation}
          onChange={(e) => setState((state) => ({ ...state, monthlyFee: strToNumber(e.target.value, undefined)}))}
          disabled={props.editDisabled}
        />
        <CheckboxInput
          label="月額を自動計算する"
          id="user_contract_monthly_fee_auto_calculation"
          name="user_contract[monthly_fee_auto_calculation]"
          checked={state.monthlyFeeAutoCalculation}
          onChange={(e) => setState((state) => updateMonthlyFee({ ...state, monthlyFeeAutoCalculation: e.target.checked}))}
          disabled={props.editDisabled}
        />
        <MonthInput
          label="超過割引終了月"
          id="user_contract_overtime_discount_end_date"
          name="user_contract[overtime_discount_end_date]"
          value={state.overtimeDiscountEndDate}
          onChange={(value: string) => setState((state) => ({ ...state, overtimeDiscountEndDate: value }))}
          disabled={props.editDisabled}
        />
        <CheckboxInput
          label="請求データ自動生成対象外"
          name="user_contract[no_billing]"
          checked={state.noBilling}
          onChange={(e) => setState((state) => ({ ...state, noBilling: e.target.checked }))}
          disabled={props.editDisabled}
        />
        <CheckboxInput
          label="海外免税対象"
          name="user_contract[irregular]"
          checked={state.irregular}
          onChange={(e) => setState((state) => ({ ...state, irregular: e.target.checked }))}
          disabled={props.editDisabled}
        />
        <RadioGroupInput
          label="支払いタイプ"
          options={billingTimingForFormOptions}
          name="user_contract[billing_timing_for_form]"
          value={state.billingTimingForForm}
          onChange={(billingTimingForForm: BillingTimingForForm) => setState((state) => ({ ...state, billingTimingForForm }))}
          disabled={props.editDisabled}
        />
        <RadioGroupInput
          label="決済方法"
          options={paymentMethodOptions}
          name="user_contract[payment_method]"
          value={state.paymentMethod}
          onChange={onChangePaymentMethod}
          disabled={props.editDisabled}
        />
        {
          props.showCreditWarning && state.paymentMethod === 'credit' &&
          <div className="alert alert-danger d-inline-block">
            { polyglot.t('stf.user_contracts.form.robo_credit_warning_html') }
          </div>
        }
        <RadioGroupInput
          label="支払期限月　＊請求書が発行される月=当月"
          options={paymentDueTypeOptions}
          name="user_contract[payment_due_type]"
          value={state.paymentDueType}
          onChange={(paymentDueType: PaymentDueType) => setState((state) => ({ ...state, paymentDueType }))}
          disabled={props.editDisabled}
        />
        <SelectInput
          label="支払期限日"
          id="user_contract_payment_due_day_"
          name="user_contract[payment_due_day]"
          options={paymentDueDayOptions}
          value={state.paymentDueDay}
          onChange={(paymentDueDay: string) => setState((state) => ({ ...state, paymentDueDay }))}
          disabled={props.editDisabled}
        />
        <RadioGroupInput
          label="請求書配信日"
          options={billSendingTimingOptions}
          name="user_contract[bill_sending_timing]"
          value={state.billSendingTiming}
          onChange={(billSendingTiming: BillSendingTiming) => setState((state) => ({ ...state, billSendingTiming }))}
          disabled={props.editDisabled}
        />
        <TextAreaInput
          label="備考"
          id="user_contract_memo"
          name="user_contract[memo]"
          value={state.memo}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setState((state) => ({ ...state, memo: e.target.value }))}
        />

        <input
          type="submit"
          value={props.userContractId != null ? '更新' : '作成'}
          className="btn btn-default submit-button"
          data-disable-with="送信中..."
          disabled={state.userContractPlanId == null}
        />
      </form>
    </React.StrictMode>
  );
}
export default UserContractForm;
