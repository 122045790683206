import * as React from 'react';
import { debounce } from 'lodash-es';
import DateInput from '../form/DateInput';
import NumberInput from '../form/NumberInput';
import HoursMinsInput from '../form/HoursMinsInput';
import CheckboxInput from '../form/CheckboxInput';
import RadioGroupInput from '../form/RadioGroupInput';
import SearchSelectInput from '../form/SearchSelectInput';
import { strToNumber } from '../../../lib/math';
import { fetchPlan } from '../../api/user_contract_plans';
import { fetchPricing, Result } from '../../api/pricing_simulators';
import type {
  BillingTimingForForm,
} from '../../models/UserContract';
import {
  billingTimingForFormOptions,
} from '../../models/UserContract';

type State = {
  userContractPlanId: number | undefined,
  months: number | undefined,
  beginDate: string | undefined,
  allottedMinutes: number,
  manualMonthlyFee: number | undefined,
  manualMonthlyFeeInput: boolean,
  billingTimingForForm: BillingTimingForForm,
  resultHtml: string | undefined,
  oneOff: boolean,
};

type Props = {
  userContractPlans: { id: number, name: string }[],
};

const defaultState: State = {
  userContractPlanId: undefined,
  months: 0,
  beginDate: '',
  allottedMinutes: 0,
  manualMonthlyFee: 0,
  manualMonthlyFeeInput: false,
  billingTimingForForm: 'in_advance',
  resultHtml: undefined,
  oneOff: false,
};

const PricingSimulatorForm = (props: Props) => {
  const [state, setState] = React.useState<State>(defaultState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPricingResult_ = async (newState: State) => {
    const { userContractPlanId, months, beginDate, manualMonthlyFee, allottedMinutes, billingTimingForForm, manualMonthlyFeeInput } = newState;

    if (userContractPlanId && beginDate) {
      let result: Result | undefined = undefined;
      try {
        result = await fetchPricing({
          userContractPlanId,
          months,
          allottedMinutes,
          beginDate,
          manualMonthlyFee: manualMonthlyFeeInput ? manualMonthlyFee : undefined,
          billingTimingForForm,
        });
      } finally {
        setLoading(false);
      }

      if (result == null) return;

      const resultHtml = result.html;
      setState((state) => ({ ...state, resultHtml }));
    }
  }
  const fetchPricingResult = React.useMemo(() => debounce(fetchPricingResult_, 500), []);

  const onDataChanged = async (newState: State) => {
    const { userContractPlanId, beginDate } = newState;

    setState({ ...newState, resultHtml: undefined });
    if (userContractPlanId && beginDate) {
      setLoading(true);
      fetchPricingResult(newState);
    }
  };

  const onPlanChanged = async (value: string) => {
    const userContractPlanId = strToNumber(value, undefined)
    setState((state) => ({ ...state, userContractPlanId }));
    if (userContractPlanId == null) return;

    fetchPricingResult.cancel();
    const plan = await fetchPlan(userContractPlanId);
    if (plan == null) return;

    setState({
      ...defaultState,
      userContractPlanId,
      months: plan.months,
      oneOff: plan.periodType === 'days',
    });
  };

  return (
    <React.StrictMode>
      <form className='mb-5' data-test-id="user-contract-form" onSubmit={(e) => e.preventDefault()}>
        <SearchSelectInput
          label="契約プラン"
          id="pricing_simulator_form_user_contract_plan_id"
          name="pricing_simulator_form[user_contract_plan_id]"
          value={state.userContractPlanId?.toString() || ''}
          options={props.userContractPlans.map((plan) => ({ value: plan.id.toString(), label: plan.name }))}
          onChange={onPlanChanged}
        />
        {
          !state.oneOff &&
          <NumberInput
            label="契約期間（月）"
            id="pricing_simulator_form_months"
            name="pricing_simulator_form[months]"
            value={state.months}
            min={1}
            className="width-auto"
            onChange={
              (e) => {
                const months = strToNumber(e.target.value, undefined);
                onDataChanged({ ...state, months });
              }
            }
          />
        }
        <DateInput
          label="開始日"
          id="pricing_simulator_form_begin_date"
          name="pricing_simulator_form[begin_date]"
          value={state.beginDate}
          className="width-auto"
          onChange={
            (e) => {
              const beginDate = e.target.value;
              onDataChanged({ ...state, beginDate });
            }
          }
        />
        <HoursMinsInput
          label="契約時間"
          id="pricing_simulator_form_allotted_minutes"
          value={state.allottedMinutes}
          name="pricing_simulator_form[allotted_minutes]"
          onChange={
            (allottedMinutes: number) => {
              onDataChanged({ ...state, allottedMinutes });
            }
          }
        />
        <CheckboxInput
          label="月額を手動入力する"
          id="pricing_simulator_form_manual_monthly_fee_input"
          name="pricing_simulator_form[manual_monthly_fee_input]"
          checked={state.manualMonthlyFeeInput}
          onChange={
            (e) => {
              const manualMonthlyFeeInput = e.target.checked;
              onDataChanged({ ...state, manualMonthlyFeeInput });
            }
          }
        />
        {
          state.manualMonthlyFeeInput &&
          <NumberInput
            label="月額"
            id="pricing_simulator_form_manual_monthly_fee"
            name="pricing_simulator_form[manual_monthly_fee]"
            value={state.manualMonthlyFee}
            min={0}
            className="width-auto"
            onChange={
              (e) => {
                const manualMonthlyFee = strToNumber(e.target.value, undefined);
                onDataChanged({ ...state, manualMonthlyFee });
              }
            }
          />
        }
        {
          !state.oneOff &&
          <RadioGroupInput
            label="支払いタイプ"
            options={billingTimingForFormOptions}
            name="pricing_simulator_form[billing_timing_for_form]"
            value={state.billingTimingForForm}
            onChange={
              (billingTimingForForm: BillingTimingForForm) => {
                onDataChanged({ ...state, billingTimingForForm });
              }
            }
          />
        }
      </form>
      {
        loading ?
          <div className="text-center">
            <i className="fas fa-spinner fa-spin fa-5x" />
          </div>
          :
          state.resultHtml &&
            <div dangerouslySetInnerHTML={{ __html: state.resultHtml }} />
      }
    </React.StrictMode>
  );
};

export default PricingSimulatorForm;
