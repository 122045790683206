import * as React from 'react'
import StaffSearch, { OptionProps } from '@/workforce/components/staffs/StaffSearch';
import Modal from '@/workforce/components/Modal';
import type { MultiSelectValue } from '@/workforce/components/utils/ReactSelectUtils';
import polyglot from '@/lib/polyglot';

type Props = {
  isOpen: boolean,
  showSchedule: boolean,
  selectedStaffs: MultiSelectValue,
  excludingStaffIds?: number[],
  commentedStaffIds?: number[],
  offeredStaffIds?: number[],
  title: string,
  okLabel: string,
  onChangeSelected?: (value: MultiSelectValue) => void,
  onOk: () => void,
  onCancel: () => void,
  disabled?: boolean,
} & OptionProps;

const StaffSearchModal = (props: Props) => {
  const { isOpen } = props;
  const disabled = props.disabled ?? false;


  const footerComponent = (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn btn-primary staff-search-modal__button"
        disabled={props.selectedStaffs.length === 0 || disabled}
        data-test-id="assign-modal-ok-button"
        onClick={() => props.onOk()}>
        {props.okLabel}
      </button>
      <button
        type="button"
        className="btn btn-default staff-search-modal__button ml-4"
        onClick={() => props.onCancel()}>
        {polyglot.t('js.staff_search.modal.cancel')}
      </button>
    </div>
  );
  return (
    <Modal
      title={props.title}
      isOpen={isOpen}
      onCancel={props.onCancel}
      footer={footerComponent}>
      <div style={{marginTop: '1.5rem'}}>
        <StaffSearch
          assignMode
          showSchedule={props.showSchedule}
          teamOptions={props.teamOptions}
          dutyOptions={props.dutyOptions}
          skillOptions={props.skillOptions}
          showRemarks={false}
          selectedStaffs={props.selectedStaffs}
          excludingStaffIds={props.excludingStaffIds || []}
          commentedStaffIds={props.commentedStaffIds || []}
          offeredStaffIds={props.offeredStaffIds || []}
          onChangeSelected={props.onChangeSelected}
        />
      </div>
    </Modal>
  );
}

export default StaffSearchModal;
