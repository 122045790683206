import cable from '../../lib/cable';

let subscription = null;

function messageReceived(data) {
  const commentId = data.comment_id;
  if ($(`.js-related-comments-comment[data-comment-id='${commentId}']`).length === 0) {
    $('.js-related-comments').prepend(data.html);
  }
}

$(document).on('turbolinks:load', function() {
  if ($('.js-related-comments').length > 0) {
    if (subscription != null) return;

    subscription = cable.subscriptions.create("RelatedCommentsChannel", {
      received: messageReceived
    });
  } else if (subscription != null) {
    subscription.unsubscribe();
    subscription = null;
  }
});
