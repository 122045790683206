const updateMainUserFields = function() {
  const defautBillingUserId = $('.js-user-company-select').find('option:selected').data('default-billing-user-id');
  const userId = $('.js-user-id').data('user-id');
  const isMainUser = (defautBillingUserId == null) || (defautBillingUserId === userId);
  const $individual = $('.js-individual-billing');
  $('.js-main-user-only-field').prop('disabled', !isMainUser && !$individual.prop('checked'));
};

$(document).on('turbolinks:load', function() {
  $('.js-user-company-select').on('change', e => updateMainUserFields());
  $('.js-individual-billing').on('change', e => updateMainUserFields());
  updateMainUserFields();
});
