type Plan = {
  id: number,
  name: string,
  periodType: string,
  feePerHour: number,
  overtimeFee: number,
  months: number,
};

export const fetchPlan = async (id: number): Promise<Plan | undefined> => {
  try {
    const response = await fetch(`/stf/user_contract_plans/${id}`);
    const json = await response.json();
    return {
      id: json.id,
      name: json.name,
      periodType: json.period_type,
      feePerHour: json.fee_per_hour,
      overtimeFee: json.overtime_fee,
      months: json.months,
    };
  } catch(_error) {
    alert('契約プラン情報の取得に失敗しました。')
  }
}
