import * as React from 'react';
import { debounce } from 'lodash-es';
import { objectToRailsQueryString } from '@/lib/query';
import { copyElementDataToClipboard } from '@/lib/clipboard';
import SearchMultiSelectInput from '../form/SearchMultiSelectInput';
import TextInput from '../form/TextInput';
import CheckboxInput from '../form/CheckboxInput';
import useSWR from 'swr';
import type { Staff } from '@/workforce/types/models';
import type { MultiSelectValue, SelectOption } from '@/workforce/components/utils/ReactSelectUtils';
import polyglot from '@/lib/polyglot';

export type OptionProps = {
  teamOptions: SelectOption[],
  dutyOptions: SelectOption[],
  skillOptions: SelectOption[],
  teamIds?: number[] | undefined,
  duties?: string[] | undefined,
  skillIds?: number[] | undefined,
  name?: string,
  immediate?: boolean,
  recentlyActive?: boolean,
}
type Props = {
  assignMode?: boolean,
  showSchedule: boolean,
  showRemarks: boolean,
  selectedStaffs?: MultiSelectValue,
  excludingStaffIds?: number[],
  commentedStaffIds?: number[],
  offeredStaffIds?: number[],
  onChangeSelected?: (value: MultiSelectValue) => void,
} & OptionProps;

type SearchParams = {
  teamIds: number[] | undefined,
  skillIds: number[] | undefined,
  duties: string[],
  name: string | undefined,
  immediate: boolean,
  recentlyActive: boolean,
  commentedStaffIds: number[] | undefined,
  offeredStaffIds: number[] | undefined,
};

type FetcherError = {
  status: number,
};

const fetcher = async (url: string): Promise<Staff[]> => {
  const response = await fetch(url, { method: 'GET', headers: { 'Accept': 'application/json' }});
  if (response.ok) {
    return await response.json();
  }
  const error: FetcherError = { status: response.status }
  throw error;
}

const useStaffSearch = (searchParams: SearchParams, replaceState: boolean) => {
  const params = {
    team_ids: searchParams.teamIds,
    duties: searchParams.duties,
    skill_ids: searchParams.skillIds,
    name: searchParams.name,
    immediate: searchParams.immediate,
    recently_active: searchParams.recentlyActive,
    commented_staff_ids: searchParams.commentedStaffIds,
    offered_staff_ids: searchParams.offeredStaffIds,
  };
  const queries = objectToRailsQueryString(params, { discardEmptyOrFalse: true });
  const url = `/stf/staff_search?${queries}`;

  React.useEffect(() => {
    if (replaceState) {
      const path = (queries === '') ? window.location.pathname : `${window.location.pathname}?${queries}`;
      history.replaceState(null, '', path);
    }
  }, [queries]);

  return useSWR<Staff[], FetcherError>(url, fetcher);
};

type StaffTableProps = {
  assignMode: boolean,
  showSchedule: boolean,
  staffs: Staff[],
  selectedStaffs: MultiSelectValue,
  commentedStaffIds: number[],
  offeredStaffIds: number[],
  onChangeSelected?: (value: MultiSelectValue) => void,
};

const StaffLabel = ({ option, onClick }: { option: SelectOption, onClick: () => void }) => {
  return <div className="staff-search-label">
    <div className="staff-search-label__name">
      { option.label }
    </div>
    <div className="staff-search-label__delete" onClick={() => onClick()}>
      ×
    </div>
  </div>;
};

const Pills = ({ commented, offered }: { commented: boolean, offered: boolean }) => {
  if (!commented && !offered) return null;

return (<div>
    { commented ? <div className="tasks-modal__comment-pill">{polyglot.t('js.staff_search.commented')}</div> : null }
    { offered ? <div className="tasks-modal__comment-pill">{polyglot.t('js.staff_search.offered')}</div> : null }
  </div>);
};

const StaffTable = (props: StaffTableProps) => {
  const {
    assignMode,
    showSchedule,
    staffs,
    selectedStaffs,
    commentedStaffIds,
    offeredStaffIds,
    onChangeSelected,
  } = props;
  const selectStaffIds = selectedStaffs.map(({ value }) => parseInt(value));

  const onCheckChanged = (staff: Staff, checked: boolean) => {
    if (onChangeSelected == null) return;

    const staffIdStr = staff.id.toString();
    if (checked) {
      if (!selectedStaffs.some((option) => option.value == staffIdStr)) {
        onChangeSelected([...selectedStaffs, { value: staffIdStr, label: staff.name }]);
      }
    } else {
      onChangeSelected(selectedStaffs.filter(({ value }) => value != staffIdStr));
    }
  }

  return (
    <table className='table table-striped table-hover sticky-table' data-test-id='staff-search-table'>
      <thead>
        <tr>
          {assignMode ? <th></th> : null}
          <th>{polyglot.t('js.staff_search.team')}</th>
          <th>{polyglot.t('js.staff_search.duty')}</th>
          <th>{polyglot.t('js.staff_search.staff_name')}</th>
          { assignMode && showSchedule ? <th>{polyglot.t('js.staff_search.schedule')}</th> : null }
          { assignMode ? <th></th> : null }
          <th>{polyglot.t('js.staff_search.last_accessed_at')}</th>
          <th>{polyglot.t('js.staff_search.available')}</th>
        </tr>
      </thead>
      <tbody>
        {
          staffs.map((staff) => {
            const checked = selectStaffIds.includes(staff.id);
            const commented = commentedStaffIds.includes(staff.id)
            const offered = offeredStaffIds.includes(staff.id)
            return (<tr key={staff.id}>
              {
                assignMode ?
                <td style={{ width: '16px' }}>
                  <input
                    type="checkbox" value={staff.id}
                    checked={checked}
                    onChange={(e) => onCheckChanged(staff, e.target.checked)}
                    data-test-staff-name={staff.name}
                  />
                </td>
                : null
              }
              <td className="staff-search-table__team-cell cursor-pointer" onClick={() => onCheckChanged(staff, !checked)}>{staff.team_name}</td>
              <td className="staff-search-table__duty-cell cursor-pointer" onClick={() => onCheckChanged(staff, !checked)}>{staff.duty}</td>
              <td className="staff-search-table__name-cell cursor-pointer" onClick={() => onCheckChanged(staff, !checked)}>
                <div>
                  <i className="far fa-envelope working-timetables__mail" data-clipboard-text={staff.email} onClick={(e) => { e.stopPropagation(); copyElementDataToClipboard(e.currentTarget);}} />
                  <a
                    className="ml-2"
                    href={`/stf/staffs/${staff.id}/skills`}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {staff.name}
                  </a>
                </div>
                {staff.mood_text !== '' && <div className="mood-text">{staff.mood_text}</div>}
              </td>
              {
                assignMode && showSchedule
                ? <td className="cursor-pointer">{staff.today_schedule ? staff.today_schedule.text : ''}</td>
                : null
              }
              {
                assignMode
                ? <td className="cursor-pointer">
                    <Pills commented={commented} offered={offered} />
                  </td>
                : null
              }
              <td className="staff-search-table__last-accessed-cell">{staff.last_acted_at}</td>
              <td className="cursor-pointer" onClick={() => onCheckChanged(staff, !checked)}>{staff.immediate_text}</td>
            </tr>);
          })
        }
      </tbody>
    </table>
  );
};

const StaffSearch = (props: Props) => {
  const assignMode = !!props.assignMode;
  const commentedStaffIds = props.commentedStaffIds || [];
  const offeredStaffIds = props.offeredStaffIds || [];
  const showSchedule = props.showSchedule;
  const [teamIds, setTeamIds] = React.useState<number[]>(props.teamIds ?? []);
  const [duties, setDuties] = React.useState<string[]>(props.duties ?? []);
  const [skillIds, setSkillIds] = React.useState<number[]>(props.skillIds ?? []);
  const [name, setName] = React.useState<string>(props.name ?? '');
  const [searchName, setSearchName] = React.useState<string>(props.name ?? '');
  const [immediate, setImmediate] = React.useState<boolean>(!!props.immediate);
  const [recentlyActive, setRecentlyActive] = React.useState<boolean>(!!props.recentlyActive);
  const [withSchedule, setWithSchedule] = React.useState<boolean>(false);
  const { data, error, isLoading } = useStaffSearch(
    {
      teamIds,
      duties,
      skillIds,
      name: searchName,
      immediate,
      recentlyActive,
      commentedStaffIds,
      offeredStaffIds,
    },
    !assignMode
  );
  const debouncedSetSearchName = React.useMemo(() => debounce(setSearchName, 500), []);
  const excludingStaffIds = props.excludingStaffIds || [];
  const staffs = data?.filter((staff) => {
    if (excludingStaffIds.includes(staff.id))
      return false;
    if (withSchedule && (staff.today_schedule == null || staff.today_schedule.day_off))
      return false;

    return true;
  });
  const selectedStaffs = props.selectedStaffs ?? [];
  const remarksHtml = polyglot.t('js.staff_search.remarks_html', { _: '' });

  return (<div>
    <div className="row">
      <div className="col-md-6" data-test-id="staff-search-team-select-container">
        <SearchMultiSelectInput
          label={polyglot.t('js.staff_search.team')}
          id="team_id"
          name="team_id"
          value={teamIds.map((id) => id.toString())}
          options={props.teamOptions}
          onChange={(values: string[]) => setTeamIds(values.map((value) => parseInt(value)))}
        />
      </div>
      <div className="col-md-6" data-test-id="staff-search-duty-select-container">
        <SearchMultiSelectInput
          label={polyglot.t('js.staff_search.duty')}
          id="staff_duties"
          name="staff_duties"
          value={duties}
          options={props.dutyOptions}
          onChange={(value: string[]) => {
            setDuties(value);
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6" data-test-id="staff-search-skill-select-container">
        <SearchMultiSelectInput
          label={polyglot.t('js.staff_search.skill')}
          id="skills"
          name="skills"
          value={skillIds.map((id) => id.toString())}
          options={props.skillOptions}
          onChange={(values: string[]) => setSkillIds(values.map((value) => parseInt(value)))}
        />
      </div>
      <div className="col-md-6">
        <TextInput
          label={polyglot.t('js.staff_search.staff_name')}
          id="staff_name"
          name="staff_name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
            debouncedSetSearchName(event.target.value);
          }}
        />
      </div>
    </div>
    <div className="d-flex">
      <CheckboxInput
        label={polyglot.t('js.staff_search.available')}
        id="staff_immediate"
        name="staff_immediate"
        checked={immediate}
        onChange={
          (e) => {
            setImmediate(e.target.checked);
          }
        }
      />
      <div className="ml-4">
        <CheckboxInput
          label={polyglot.t('js.staff_search.recently_active')}
          id="staff_recently_active"
          name="staff_recently_active"
          checked={recentlyActive}
          onChange={
            (e) => {
              setRecentlyActive(e.target.checked);
            }
          }
        />
      </div>
      {
        showSchedule
        ? <div className="ml-4">
            <CheckboxInput
              label={polyglot.t('js.staff_search.has_todays_schedule')}
              id="with_schedule"
              name="with_schedule"
              checked={withSchedule}
              onChange={
                (e) => {
                  setWithSchedule(e.target.checked);
                }
              }
            />
          </div>
        : null
      }
    </div>

    { props.showRemarks && remarksHtml && <div className="alert alert-warning" dangerouslySetInnerHTML={{ __html: remarksHtml}} /> }

    {
      assignMode && (<div className="staff-search__labels-container">
        <span className="staff-search__selected-label">{polyglot.t('js.staff_search.selected_staffs')}:</span>
        { selectedStaffs.length > 0 ?
            selectedStaffs.map((option) =>
            <StaffLabel
              key={option.value}
              option={option}
              onClick={() => props.onChangeSelected && props.onChangeSelected(selectedStaffs.filter((selected) => selected.value !== option.value))}
            />
            )
            : polyglot.t('js.staff_search.not_selected')
        }
      </div>)
    }

    {
      isLoading ?
      <div className="staff-search__loading">{polyglot.t('js.staff_search.searching')}</div>
      : error ?
      <div className="staff-search__loading">{polyglot.t('js.staff_search.fetch_error')}</div>
      : staffs ?
      <StaffTable
        assignMode={assignMode}
        showSchedule={showSchedule}
        staffs={staffs}
        selectedStaffs={props.selectedStaffs ?? []}
        commentedStaffIds={props.commentedStaffIds ?? []}
        offeredStaffIds={props.offeredStaffIds ?? []}
        onChangeSelected={props.onChangeSelected}
      />
      : <></>
    }
  </div>);
};

export default StaffSearch;
