import * as React from 'react'
import { Notification } from '../../models/Notification';
import { stfNotificationPath } from '../../../lib/rails-routes';
import NotificationComment from './NotificationComment';
import polyglot from '@/lib/polyglot';

type Props = {
  notification: Notification,
  onAction?: (notificationId: number) => void
};

const Title = (props: Props) => {
  const { notification } = props;
  const { task } = notification.target;
  const title = task ? task.title : polyglot.t('js.notifications.default_title');
  return (<a href={stfNotificationPath({ id: notification.id })} className="notification__title">{title}</a>);
};

const ActionLink = (props: Props) => {
  const { notification } = props;
  const text = notification.readAt != null ? polyglot.t('js.notifications.mark_as_unread') : polyglot.t('js.notifications.mark_as_read');
  const iconClass = notification.readAt != null ? 'fas fa-undo' : 'fas fa-check'

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (props.onAction) {
      props.onAction(props.notification.id);
    }
  };
  return <a href="#" onClick={onClick}><i className={iconClass}/>{text}</a>
}

const NotificationItem = (props: Props) => {
  const { notification } = props;
  const { task } = notification.target;
  const userName = task?.user.name;

  return (<li className="notification" data-test-id={`notification_${notification.id}`}>
    <div className="d-flex justify-content-between align-items-end">
      <div>
        <Title notification={notification} />
        { userName && <div className="notification__user-name">{userName}</div> }
      </div>
      <ActionLink {...props} />
    </div>
    <NotificationComment comment={notification.target} />
  </li>);
};
export default NotificationItem;
