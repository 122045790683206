import * as React from 'react';

export type LabelProps = {
  label?: string | undefined,
  id?: string | undefined,
  required?: boolean,
}

const Label = (props: LabelProps) => {
  if (props.label == null) return null;

  return <label htmlFor={ props.id } className="control-label">
    { props.label }
    { " " }
    { props.required && <span className="required-mark">必須</span> }
  </label>;
};
export default Label;
