import { delegateEventListener, toggle, ready } from '../../lib/vanilla_utils';

const checkBoxSelector = '.js-collapse-disable-fields-checkbox';
const scopeSelector = '.js-collapse-disable-fields-scope';

const updateTargetFields = (scopeElem) => {
  if (scopeElem == null) return;

  const hidden = scopeElem.querySelector(`${checkBoxSelector}:checked`) != null;
  scopeElem.querySelectorAll('.js-collapse-disable-fields-target').forEach((elem) => {
    toggle(elem, !hidden);
    elem.querySelectorAll('input, textarea, select').forEach((input) => input.disabled = hidden)
  });
}

delegateEventListener(document, 'change', checkBoxSelector, function(_target, e) {
  updateTargetFields(e.target.closest(scopeSelector));
})

ready(() => {
  document.querySelectorAll(scopeSelector).forEach((elem) => {
    updateTargetFields(elem);
  })
});
