import $ from 'jquery';

let timer = -1;
let isDragging = false;

const onBodyDrop = (e) => {
  if ($('.js-file-drop-target').length > 0) {
    e.preventDefault();
  }
  $(e.currentTarget).removeClass('is-dragging');
};

const onBodyDragEnter = (e) => {
  e.preventDefault();
  isDragging = true;
  $(e.currentTarget).addClass('is-dragging');
};

const onBodyDragOver = (e) => {
  e.preventDefault();
  isDragging = true;
};

const onBodyDragLeave = (e) => {
  e.preventDefault();
  const currentTarget = e.currentTarget;
  isDragging = false;
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (!isDragging) {
      return $(currentTarget).removeClass('is-dragging');
    }
  }, 200);
};

window.App.initFileDropTarget = () => {
  $('.js-file-drop-target').on('drop', (e) => {
    e.preventDefault();
    let $scope = $(e.currentTarget).closest('.js-file-drop-scope, form');
    let $input = $scope.find('.js-file-drop-input');
    if ($input.length) {
      $input.get(0).files = e.originalEvent.dataTransfer.files;
      // change イベントを発生させて bootstrap-filestyle を更新する
      $input.trigger('change');
    }
  });
};

$(document).on('turbolinks:before-cache', ()  => {
  $('body').off('drop', onBodyDrop);
  $('body').off('dragenter', onBodyDragEnter);
  $('body').off('dragleave', onBodyDragLeave);
  $('body').off('dragover', onBodyDragOver);
});

$(document).on('turbolinks:load', () => {
  App.initFileDropTarget();
  $('body').on('drop', onBodyDrop);
  $('body').on('dragenter', onBodyDragEnter);
  $('body').on('dragleave', onBodyDragLeave);
  $('body').on('dragover', onBodyDragOver);
});
