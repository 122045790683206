type Args = {
  userContractPlanId: number,
  months: number | undefined,
  allottedMinutes: number,
  beginDate: string,
  manualMonthlyFee: number | undefined,
  billingTimingForForm: string,
};

export type Result = {
  html: string,
  oneOff: boolean,
};

export const fetchPricing = async (args: Args): Promise<Result | undefined> => {
  try {
    const params = {
      forms_pricing_simulator_form: {
        user_contract_plan_id: args.userContractPlanId,
        months: args.months,
        allotted_minutes: args.allottedMinutes,
        begin_date: args.beginDate,
        manual_monthly_fee: args.manualMonthlyFee,
        billing_timing_for_form: args.billingTimingForForm
      }
    }
    const response = await fetch('/pricing_simulator', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    return {
      html: json.html,
      oneOff: json.one_off,
    };
  } catch(_error) {
    alert('情報の取得に失敗しました。')
  }
}
