import $ from 'jquery';
import 'bootstrap-filestyle';
import polyglot from '@/lib/polyglot';

const fileInputSelectors = ['.js-file-upload', '.js-avatar-upload'];

window.App.initFileInputs = () => {
  fileInputSelectors.forEach((selector) => {
    $(selector).filestyle({
      buttonBefore: true,
      buttonText: polyglot.t('js.input.file.button_text'),
      buttonName: 'btn-default',
      placeholder: polyglot.t('js.input.file.button_text'),
    });
  });
};

const terminateFileInputs = () => {
  fileInputSelectors.forEach((selector) => $(selector).filestyle('destroy'));
};

$(document).on('turbolinks:load', App.initFileInputs);
$(document).on('turbolinks:before-cache', terminateFileInputs);
