import { onConnect } from '@sonicgarden/onconnect';
import polyglot from '@/lib/polyglot';

// Linux の NAME_MAX = 255
// Linux ではファイル名のUTF-8での長さが255以下でないとエラーになるが、
// Windows や Mac では制限がゆるい
// carrierwave でのアップロード時にファイル名が長すぎるとエラーになるのでチェックする
const NAME_MAX = 255;

function validateFileNameLength(name: string): boolean {
  const utf8ByteLength = new TextEncoder().encode(name).length
  return utf8ByteLength <= NAME_MAX;
}

function onChangeFileInput(event: Event) {
  const inputElement = event.currentTarget as HTMLInputElement;
  if (inputElement.files == null) return;

  const files = Array.from(inputElement.files);
  if (files.some((file) => !validateFileNameLength(file.name))) {
    const message = polyglot.t('js.input.file.name_too_long')
    inputElement.setCustomValidity(message);
    inputElement.reportValidity();
  } else {
    inputElement.setCustomValidity('');
  }
}

onConnect('input[type="file"]', (input) => {
  input.addEventListener('change', onChangeFileInput);
})
