const updateBranchSelect = function(branches){
  const $select = $('.js-bank-branch-name');
  $select.html($('<option>'));
  $.each(branches, function(i) {
    const option = $(`<option data-code='${this.code}'>`).val(this.name).text(this.name);
    $select.append(option);
  });
  $select.trigger('change');
  updateBranchCode();
};

const requestUpdateBranchSelect = function() {
  const code = $('.js-bank-code').val();
  if (code.length === 4) {
    $.ajax({
      url: `/stf/banks/${code}`,
      method: 'GET',
      dataType: 'json',
      success(data, status, xhr) {
        updateBranchSelect(data.branches);
      },
      error(xhr, status, errorThrown) {
        alert('銀行支店コードが取得できませんでした');
      }
    });
  }
};

var updateBranchCode = () => $('.js-bank-branch-code').val($('.js-bank-branch-name').find('option:selected').data('code'));

$(document).on('turbolinks:before-cache', () => $('.js-bank-name, .js-bank-branch-name').select2('destroy'));

$(document).on('turbolinks:load', function() {
  const $bankCodeInput = $('.js-bank-code');
  if (!$bankCodeInput.length) { return; }
  const $bankNameSelect = $('.js-bank-name');
  const $branchNameSelect = $('.js-bank-branch-name');
  $('.js-bank-name, .js-bank-branch-name').select2({
    dropdownParent: $('#wrapper'),
    language: {
      noResults: () => '候補が見つかりません',
    }
  });

  $bankNameSelect.on('change', function(e) {
    const value = $(this).val();
    if (value !== "") {
      const code = $(this).find(`option[value='${value}']`).data('code');
      $bankCodeInput.val(code);
      requestUpdateBranchSelect();
    }
  });

  $branchNameSelect.on('change', () => updateBranchCode());

  const setupCodeToName = ($codeInput, $nameSelect) => $codeInput.on('change', function() {
    const code = $(this).val();
    const option = $nameSelect.find(`option[data-code='${code}']`);
    if (option != null) {
      $nameSelect.val(option.val()).trigger('change');
    }
  });

  setupCodeToName($bankCodeInput, $bankNameSelect);
  setupCodeToName($('.js-bank-branch-code'), $branchNameSelect);
});
