import * as React from 'react';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';
import { clamp, strToNumber } from '../../../lib/math';

type Props = LabelProps & {
  value: number,
  name: string,
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  readOnly?: boolean,
  disabled?: boolean,
  min?: number | undefined,
  max?: number | undefined,
  onChange?: (value: number) => void,
}

function maxAsString(value: string, value2: number): string {
  const num = strToNumber(value, 0);
  return Math.max(num, value2).toString();
}

function clampAsString(value: string, min: number, max: number): string {
  const num = strToNumber(value, 0);
  return clamp(num, min, max).toString();
}

const calcHoursPart = (minutes: number) => Math.floor(minutes / 60).toString();
const calcMinutesPart = (minutes: number) => (minutes % 60).toString();

const useHoursMinutesState = (props: Props) => {
  const [hours, setHours] = React.useState<string>(calcHoursPart(props.value));
  const [minutes, setMinutes] = React.useState<string>(calcMinutesPart(props.value));
  const [totalMinutes, setTotalMinutes] = React.useState<string>(props.value.toString());
  React.useEffect(() => {
    setHours(calcHoursPart(props.value));
    setMinutes(calcMinutesPart(props.value));
    setTotalMinutes(props.value.toString());
  }, [props.value])

  const onChangeValues = (hoursStr: string, minutesStr: string) => {
    const hours = strToNumber(hoursStr, undefined);
    const minutes = strToNumber(minutesStr, undefined);
    if (hours == null || minutes == null || hours < 0 || minutes < 0 || 59 < minutes) {
      setTotalMinutes('');
      return;
    }

    const newValue = hours * 60 + minutes;
    const newValueStr = newValue.toString()
    setTotalMinutes(newValueStr);
    if (props.onChange && totalMinutes !== newValueStr) {
      props.onChange(newValue);
    }
  };

  return { hours, setHours, minutes, setMinutes, totalMinutes, setTotalMinutes, onChangeValues };
};

const HoursMinsInput = (props: Props) => {
  const { hours, setHours, minutes, setMinutes, totalMinutes, onChangeValues } = useHoursMinutesState(props);
  const onChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHours(e.target.value);
    onChangeValues(e.target.value, minutes);
  };
  const onChangeMins = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinutes(e.target.value);
    onChangeValues(hours, e.target.value);
  };

  const { label, id, required } = props;
  const hoursStyle = { width: '6em' };
  const minutesStyle = { width: '6em', marginLeft: '1em' };
  return <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
    <input type="hidden" id={id} name={props.name} value={totalMinutes} />
    <div className="form-inline">
      <input
        type="number"
        id={id ? `${id}_hours_part` : undefined}
        value={hours}
        min="0"
        className="form-control"
        style={hoursStyle}
        required
        readOnly={props.readOnly}
        disabled={props.disabled}
        onChange={onChangeHours}
        onBlur={(e) => {
          const newValue = maxAsString(hours, 0);
          setHours(newValue);
          onChangeValues(newValue, minutes);
        }
      }
      >
      </input>
      { " 時間 " }
      <input
        type="number"
        id={id ? `${id}_minutes_part` : undefined}
        value={minutes}
        min="0"
        max="59"
        className="form-control"
        style={minutesStyle}
        required
        readOnly={props.readOnly}
        disabled={props.disabled}
        onChange={onChangeMins}
        onBlur={(e) => {
          const newValue = clampAsString(minutes, 0, 59);
          setMinutes(newValue);
          onChangeValues(hours, newValue);
        }}
      >
      </input>
      { " 分 " }
    </div>
  </FormGroup>
}
export default HoursMinsInput;
