import React from 'react'
import { rewrap } from '@sonicgarden/rewrap';

type Component = React.FunctionComponent<any> | React.ComponentClass<any>

const ErrorBoundary = window.bugsnagClient!.getPlugin('react')!
  .createErrorBoundary(React)

export function rewrapE(name: string, component: Component, hasChildren = false) {
  const Wrapped = component;
   const wrapper = (props: any) => (
    <ErrorBoundary>
      <Wrapped {...props} />
    </ErrorBoundary>
  );
  rewrap(name, wrapper, hasChildren);
}
