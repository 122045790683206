import { gaSkillEvent } from '@/lib/gaEvents';

const currentSkills = (addSkills) => {
  const $tokenInput = $('.js-staff-shifts-token-input');
  if ($tokenInput.length > 0) {
    const skills = $tokenInput.tokenInput('get').map(hash => hash.name);
    return skills.concat(addSkills);
  } else {
    return addSkills;
  }
};

const buildCheckedStaffIds = () => {
  const checked = $('.js-staffs-checkbox:checked').map((_, elem) => $(elem).val()).toArray();
  const hidden  = $('.js-staffs-hidden-check').map((_, elem) => $(elem).val()).toArray();
  return checked.concat(hidden);
};

const fetchStaffShifts = ({addSkills = [], all = false, immediately = false, checked = false, clearChecks = false, setWorkingTimetables = false}) => {
  const checkedStaffIds = clearChecks ? [] : buildCheckedStaffIds();
  const $staffShifts = $('.js-staff-shifts');
  const duty = $('.js-staff-shifts-duty-button.active').data('duty');
  const skills = all ? [] : currentSkills(addSkills).filter(Boolean);
  const hasWorkingTimetables = (setWorkingTimetables || $('.js-staff-shifts-has-working-timetables-button.active').length > 0);
  const date = hasWorkingTimetables ? $('.js-staff-shifts-date-select').val() : '';
  const taskTitle = $('.js-staff-shifts-title').val();
  const taskContent = $('.js-staff-shifts-content').val();
  $('.js-staff-shifts-spinner').show();
  $staffShifts.html('');
  $.ajax({
    type: 'GET',
    url: $staffShifts.data('url'),
    data: {
      date: date,
      skills: skills,
      has_working_timetables: hasWorkingTimetables,
      duty: duty,
      task_id: $staffShifts.data('task-id'),
      task_title: taskTitle,
      task_content: taskContent,
      except_staff_ids: $staffShifts.data('except-staff-ids'),
      checked_staff_ids: checkedStaffIds,
      checked: checked,
      immediately: immediately,
    },
    dataType: 'script' }).then(() => {
      $('.js-staff-shifts-skill-selector').select2({ dropdownParent: $('.js-select-2-parent') });
      $('.js-staff-shifts-skill-level-selector').select2({ dropdownParent: $('.js-select-2-parent') });
      $('.js-staff-shifts-token-input').tokenInput('', {
        theme: 'workforce',
        tokenFormatter: (item) => { return `<li>${item.name}</li>`; },
        onAdd: () => { fetchStaffShifts({}); },
        onDelete: () => { fetchStaffShifts({}); },
        hintText: null,
      });
      $('.js-staff-shifts-spinner').hide();
    },
  );
};

$(document).on('click', '.js-staff-shifts-skill-button', (event) => {
  event.preventDefault();
  const skill = $(event.currentTarget).text();
  const [_, skillName, skillLevel] = /^(.+?)\s*\|\s*([^|]+)$/.exec(skill)
  gaSkillEvent(skillName, skillLevel);
  $('.js-staff-shifts-token-input').tokenInput('add', { id: skill, name: skill });
});

$(document).on('click', '.js-staff-shifts-duty-button', (event) => {
  event.preventDefault();
  const $dutyButton = $(event.currentTarget);
  if ($dutyButton.hasClass('active')) {
    $dutyButton.removeClass('active');
  } else {
    $('.js-staff-shifts-duty-button.active').removeClass('active');
    $dutyButton.addClass('active');
  }
  fetchStaffShifts({});
});

$(document).on('click', '.js-staff-shifts-checked-button', (event) => {
  event.preventDefault();
  $('.js-staff-shifts-duty-button, .js-staff-shifts-has-working-timetables-button').removeClass('active');
  fetchStaffShifts({checked: true});
});

$(document).on('click', '.js-staff-shifts-has-working-timetables-button', (event) => {
  event.preventDefault();
  $(event.currentTarget).toggleClass('active');
  $('.js-staff-shifts-date-select').toggleClass('active');
  fetchStaffShifts({});
});

$(document).on('click', '.js-staff-shifts-all-button', (event) => {
  event.preventDefault();
  $('.js-staff-shifts-duty-button, .js-staff-shifts-has-working-timetables-button').removeClass('active');
  fetchStaffShifts({all: true});
});

$(document).on('click', '.js-staff-shifts-immediately-button', (event) => {
  event.preventDefault();
  $('.js-staff-shifts-duty-button, .js-staff-shifts-has-working-timetables-button').removeClass('active');
  fetchStaffShifts({immediately: true});
});

$(document).on('click', '.js-staff-shifts-skill-selector-add', (event) => {
  event.preventDefault();
  const skillName = $('.js-staff-shifts-skill-selector').val();
  const skillLevel = $('.js-staff-shifts-skill-level-selector').val();
  gaSkillEvent(skillName, skillLevel);
  const skill = [skillName, skillLevel].join(' | ');

  $('.js-staff-shifts-token-input').tokenInput('add', { id: skill, name: skill });
});

$(document).on('turbolinks:load', () => {
  const $staffShifts = $('.js-staff-shifts:visible')
  if ($staffShifts.length > 0) {
      fetchStaffShifts({
        setWorkingTimetables: true,
      });
  }
});

$(document).on('change', '.js-staff-shifts-date-select', () => {
  fetchStaffShifts({});
});
