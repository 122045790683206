import * as React from 'react'
// import { useMountedState } from 'react-use'; だと型エラーが出る
// SEE: https://github.com/streamich/react-use/issues/2074
import useMountedState from 'react-use/lib/useMountedState';
import NotificationsView from '../components/notifications/NotificationsView';
import { Tab } from '../components/notifications/NotificationTabs';
import { Notification } from '../models/Notification';
import { showNotice, showAlert } from '@/lib/toastify'
import * as $ from 'jquery';
import polyglot from '@/lib/polyglot';
import { readAllStfNotificationsPath, readStfNotificationPath, unreadStfNotificationPath } from '../../lib/rails-routes';

const { useState } = React;

type Props = {
  tabs: Tab[],
  activeTabId: Tab['tabId']
  notifications: Notification[],
  children?: React.ReactNode,
}

type CountByTab = {
  inbox: number,
  working_minutes: number,
  read?: number,
  all: number,
}

async function update(url: string, params: any, message: string) {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token: $.rails.csrfToken(),
        ...params,
      })
    });
    const { count_by_tab }: { count_by_tab: CountByTab } = await response.json();
    showNotice(message);
    return count_by_tab;
  } catch {
    showAlert(message);
    return undefined;
  }
}

const updateTabCount = (tabs: Tab[], countByTab: CountByTab) => {
  return tabs.map(tab => ({ ...tab, count: countByTab[tab.tabId] }));
}

// NOTE: サイドナビの通知数表示はコンポーネント外なので、無理やり更新している
const updateAllNotificationCount = (count: number) => {
  const elem = document.querySelector('[data-notification-count-id=all]');
  if (elem) {
    elem.innerHTML = count.toString();
    elem.classList.toggle('d-none', count === 0);
  }
}

const actionPathAndMessage = (tabId: Tab['tabId'], notificationId: number) => {
  if (tabId === 'read') {
    return {
      url: unreadStfNotificationPath({ id: notificationId }),
      message: polyglot.t('js.notifications.marked_as_unread'),
    };
  } else {
    return {
      url: readStfNotificationPath({ id: notificationId }),
      message: polyglot.t('js.notifications.marked_as_read'),
    };
  }
}

const useContainerStates = (props: Props) => {
  const [tabs, setTabs] = useState(props.tabs);
  const [notifications, setNotifications] = useState(props.notifications);
  const [showPagination, setShowPagination] = useState(true);
  const isMounted = useMountedState();

  const onReadAll = async () => {
    if (!confirm(polyglot.t('js.notifications.confirm_read_all'))) return;

    const countByTab = await update(readAllStfNotificationsPath(), { category: props.activeTabId }, polyglot.t('js.notifications.marked_all_as_read'));
    if (isMounted() && countByTab) {
      setNotifications([]);
      setTabs(updateTabCount(tabs, countByTab));
      updateAllNotificationCount(countByTab.all);
      setShowPagination(false);
    }
  };

  const onAction = async (notificationId: number) => {
    const { url, message } = actionPathAndMessage(props.activeTabId, notificationId);
    const countByTab = await update(url, {}, message);
    if (isMounted() && countByTab) {
      const newValue = notifications.filter(notification => notification.id !== notificationId);
      setNotifications(newValue);
      setTabs(updateTabCount(tabs, countByTab));
      updateAllNotificationCount(countByTab.all);
    }
  }

  return {
    tabs,
    notifications,
    onReadAll,
    onAction,
    showPagination,
  };
}

const NotificationsContainer = (props: Props) => {
  const { tabs, notifications, onReadAll, onAction, showPagination } = useContainerStates(props);

  return (
    <NotificationsView
      activeTabId={props.activeTabId}
      tabs={tabs}
      notifications={notifications}
      onReadAll={onReadAll}
      onAction={onAction}
      children={showPagination ? props.children : undefined}
    />
  );
};
export default NotificationsContainer;
