$(document).on('click', '.js-bulk-user-bills-action', (e) => {
  e.preventDefault();
  const $link = $(e.currentTarget);
  const url = $link.data('url');
  const method = $link.data('form-method');
  const $form = $('.js-bulk-user-bills-action-form');
  $form.attr('action', url);
  $form.find('input[name="_method"]').val(method);
  $form.trigger('submit');
});

$(document).on('click', '.js-bulk-update-sending-date', (e) => {
  e.preventDefault();
  const $modal = $('#update-sending-date-modal');
  const $checkBoxes = $('.js-bulk-check-box:checked');
  const $fieldContainer = $('.js-checked-user-bill-ids')
  $fieldContainer.empty();
  $checkBoxes.each((_, elem) => {
    if (elem.checked) {
      $fieldContainer.append(`<input type="hidden" name="user_bill_ids[]" value="${elem.value}">`);
    }
  });
  $modal.modal();
})
