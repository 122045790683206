import { contractParamsStfUserUserContractsPath } from '../../lib/rails-routes';

type ContractParams = {
  renewalDate: string,
  cancellationLimitDate: string,
  endDate: string | undefined,
  overtimeDiscountEndDate: string,
  firstMonthAllottedMinutes: number,
};

type ContractParamsArgs = {
  userId: number,
  userContractId: number | undefined,
  userContractPlanId: number,
  beginDate: string,
  allottedMinutes: number,
  firstRenewalDate: string | undefined,
  months: number | undefined
};

export const fetchContractParams = async ({ userId, userContractId, userContractPlanId, beginDate, allottedMinutes, firstRenewalDate, months } : ContractParamsArgs): Promise<ContractParams | undefined> => {
  try {
    const params: Record<string, string> = {
      user_contract_plan_id: userContractPlanId.toString(),
      begin_date: beginDate,
      first_renewal_date: firstRenewalDate ?? '',
      allotted_minutes: allottedMinutes.toString(),
      months: months != null ? months.toString() : '',
    }
    if (userContractId != null) {
      params.user_contract_id = userContractId.toString();
    }
    const searchParams = new URLSearchParams(params);
    const path = contractParamsStfUserUserContractsPath({ userId });
    const response = await fetch(`${path}?${searchParams.toString()}`);
    if (response.status === 204) return;

    const json = await response.json();
    return {
      renewalDate: json.renewal_date,
      cancellationLimitDate: json.cancellation_limit_date,
      endDate: json.end_date ?? undefined,
      overtimeDiscountEndDate: json.overtime_discount_end_date,
      firstMonthAllottedMinutes: json.first_month_allotted_minutes,
    }
  } catch(_error) {
    alert('契約パラメータの計算時にエラーが発生しました');
  }
}
