// jQuery on の代替関数
// handler の引数は jQuery on とは違うので注意
export function delegateEventListener(parent: Node, eventName: string, selector: string, handler: (selectorTarget: Element, e: Event) => void) {
  parent.addEventListener(eventName, (e: Event) => {
    const targetElement: Element = e.target as Element;
    if (targetElement.matches(`${selector}, ${selector} *`)) {
      const elem = targetElement.closest(selector);
      if (elem && parent.contains(elem)) {
        handler(elem, e);
      }
    }
  });
}

export function show(elem: HTMLElement, display: string = 'block') {
  elem.style.display = display;
}

export function hide(elem: HTMLElement) {
  elem.style.display = 'none';
}

export function toggle(elem: HTMLElement, showElement: boolean, display: string = 'block') {
  const s = showElement == null ? elem.style.display === 'none' : showElement;
  if (s) {
    show(elem, display);
  } else {
    hide(elem);
  }
}

export function ready(handler: EventListenerOrEventListenerObject) {
  window.addEventListener('turbolinks:load', handler);
}
