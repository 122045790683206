import * as React from 'react';
import FormGroup from './FormGroup';
import type { LabelProps } from './Label';

type Props = LabelProps & {
  value?: string,
  name?: string | undefined,
  className?: string | undefined,
  wrapperClassName?: string | undefined,
  required?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  min?: number | undefined,
  max?: number | undefined,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
}

const TextInput = (props: Props) => {
  const { label, id, required } = props;
  return <FormGroup {...{ label, id, required }} className={props.wrapperClassName}>
    <input
      type="text"
      id={id}
      value={props.value ?? ''}
      name={props.name}
      className={`form-control ${props.className ?? ''}`}
      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled}
      onChange={props.onChange}
    >
    </input>
  </FormGroup>
}
export default TextInput;
