// 「担当者を指定して対応開始」モーダルを開くボタン
$(document).on('click', '.js-assigned-toggle', function () {
  $('.js-assignee-modal').modal();
  $('.js-task-working-timetable-container').html('');
  $('.js-loading-assigned-table').show();
  $.ajax({
    type: 'GET',
    url: $('.js-task-working-timetable-container').data('url'),
    dataType: 'script'
  });
});

$(document).on('change', '.js-assign-table-schedule-checkbox', function (e) {
  $('.js-task-working-timetable-container').html('');
  $('.js-loading-assigned-table').show();
  const { checked } = e.currentTarget;
  const baseUrl = $(e.currentTarget).data('url');
  const url = checked ? `${baseUrl}?with_schedule=1` : baseUrl;

  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'script'
  });
});

// よく使われる発注予定時間の設定ショートカット
$(document).on('click', '.js-set-suggestion-minutes', function () {
  const setVal = $(this).html();
  $.each([$('.js-set-minutes'), $('.js-routine-set-minutes')], function (_, $targetForm) {
    if ($targetForm.length > 0) {
      $targetForm.val(setVal);
    }
  });
});
