export type BillingTimingForForm = 'in_advance' | 'lump_sum' | 'lump_sum_with_discount' | 'deferred' | 'deferred_no_commission';
export type PaymentMethod = 'mf_kessai' | 'credit' | 'direct_debit' | 'oem' | 'other';
export type PaymentDueType = 'same_month' | 'next_month' | 'month_after_next';
export type BillSendingTiming = 'first' | 'second' | 'fifth';

export const billingTimingForFormOptions: { value: BillingTimingForForm, text: string }[] = [
  { value: 'in_advance', text: '前払い' },
  { value: 'lump_sum', text: '一括(5%割引適用無し)' },
  { value: 'lump_sum_with_discount', text: '一括(5%割引適用あり)' },
  { value: 'deferred', text: '後払い(後払い手数料あり)' },
  { value: 'deferred_no_commission', text: '後払い(後払い手数料無し)' },
];
export const paymentMethodOptions: { value: PaymentMethod, text: string }[] = [
  { value: 'mf_kessai', text: '銀行振込' },
  { value: 'credit', text: 'クレジット' },
  { value: 'direct_debit', text: '口座振替' },
  { value: 'oem', text: 'OEM' },
  { value: 'other', text: 'その他' },
];
export const paymentDueTypeOptions: { value: PaymentDueType, text: string }[]  = [
  { value: 'same_month', text: '当月' },
  { value: 'next_month', text: '翌月' },
  { value: 'month_after_next', text: '翌々月' },
];
export const billSendingTimingOptions: { value: BillSendingTiming, text: string }[] = [
  { value: 'first', text: '第１営業日' },
  { value: 'second', text: '第２営業日' },
  { value: 'fifth', text: '第５営業日' },
];

export const dueDayTable: Record<string, number> = {
  credit: 15,
  direct_debit: 26,
};

export const paymentDueDayOptions = Array.from({ length: 31 }, (_x, i) => ({ value: (i + 1).toString(), text: `${i + 1}日` }));
