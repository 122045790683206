$(document).on('change', '.js-task-quality-check-selector', function() {
  const value = $(this).val();
  if (value > 0) {
    $.ajax({
      type: 'GET',
      url: '/stf/task_quality_checks/' + value,
      dataType: 'json'}).then(function(data) {
      const taskQualityCheck = $('.js-task-quality-check');
      taskQualityCheck.val([taskQualityCheck.val(), '### ' + data.title, data.content].filter(Boolean).join('\n***\n')).trigger('input');

      const textarea = taskQualityCheck.get(0);
      if (textarea) {
        textarea.dispatchEvent(new Event('autosize:update'));
      }
    });
  }
});

$(document).on('tasklist:changed', '.js-task-list-field', () => $('#js-task-list-form').trigger("submit.rails"));

$(document).on('click', '#js-task-quality-check-clear', function(event) {
  event.preventDefault();
  $('.js-task-quality-check').val('');
  $('.js-task-quality-check-selector').val(null).trigger("change");
});
