import YubinBango from 'yubinbango.js';
import { onConnect } from '@sonicgarden/onconnect';

// 居住国を選択したときに国と都道府県のフィールドの表示制御を行う
function onChangeResidenceSelect() {
  const select = document.querySelector('.js-residence-field') as HTMLSelectElement | null;
  if (select == null) return;

  const isJapan = select.value === 'japan';

  const countryWrapper = document.querySelector('.js-country-field-wrapper') as HTMLElement | null;
  const countryInput = document.querySelector('.js-country-field') as HTMLInputElement | null;
  if (countryWrapper && countryInput) {
    countryWrapper.hidden = isJapan;
    countryInput.disabled = isJapan;
  }
  const prefectureWrapper = document.querySelector('.js-prefecture-field-wrapper') as HTMLElement | null;
  const prefectureSelect = document.querySelector('.js-prefecture-field') as HTMLSelectElement | null;
  if (prefectureWrapper && prefectureSelect) {
    prefectureWrapper.hidden = !isJapan;
    prefectureSelect.disabled = !isJapan;
  }
}

onConnect('.js-residence-field', (select) => {
  onChangeResidenceSelect()
  select.addEventListener('change', onChangeResidenceSelect);
  return () => select.removeEventListener('change', onChangeResidenceSelect);
});

async function onChangePostalCodeInput(event: Event) {
  // 管理画面で海外在住が選ばれているときは郵便番号検索をしない
  const residenceSelect = document.querySelector('.js-residence-field') as HTMLSelectElement | null;
  if (residenceSelect && residenceSelect.value === 'foreign')
    return;

  const input = event.currentTarget as HTMLInputElement;
  if (!/^\d{7}|\d{3}-\d{4}$/.test(input.value)) return;

  try {
    const address = await YubinBango.getAddress(input.value);
    const prefectureSelect = document.querySelector('.js-prefecture-field') as HTMLSelectElement | null;
    const address1Input = document.querySelector('.js-address1-field') as HTMLInputElement | null;
    if (prefectureSelect) {
      prefectureSelect.value = address.prefecture;
    }
    if (address1Input) {
      address1Input.value = `${address.locality}${address.street}`
    }
  } catch {
    // マッチするデータがないときは何もしない
  }
}

onConnect('input.js-postal-code-input', (input) => {
  input.addEventListener('change', onChangePostalCodeInput);
  return () => input.removeEventListener('change', onChangePostalCodeInput);
});
