const fetchUserProjects = userId => $.ajax({
  type: 'GET',
  url: $('.js-fetch-urls').data('projects-url'),
  data: {
    user_id: userId
  },
  dataType: 'script'
});

$(document).on('select2:select', '.js-user-select', function() {
  fetchUserProjects($(this).val());
});
$(document).on('select2:select', '.js-routine-user-select', function() {
  fetchUserProjects($('.js-routine-user-select').val());
});
