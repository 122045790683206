import * as React from 'react';
import { useState, useRef } from 'react';
import $ from 'jquery';
import StaffSearchModal from './StaffSearchModal';
import type { OptionProps as StaffSearchProps } from '@/workforce/components/staffs/StaffSearch';
import type { MultiSelectValue } from '@/workforce/components/utils/ReactSelectUtils';
import polyglot from '@/lib/polyglot';

type Props = {
  actionUrl: string,
  showSchedule: boolean,
  excludingStaffIds: number[],
  commentedStaffIds: number[],
  offeredStaffIds: number[],
  alreadyAssigned: boolean,
} & StaffSearchProps;

const AssignButton = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingStaffs, setEditingStaffs] = useState<MultiSelectValue>([]);
  const [sending, setSending] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const onCancel = () => {
    setModalOpen(false);
  };
  const onOk = () => {
    setSending(true);
    formRef.current?.submit();
  }

  const button_class = props.alreadyAssigned ? "btn btn-success" : "btn btn-warning";
  const button_label = props.alreadyAssigned ? polyglot.t('js.staff_search.assign') : polyglot.t('js.staff_search.assign_and_change_state');
  const modal_title = props.alreadyAssigned ? polyglot.t('js.staff_search.modal.title_add_assignments') : polyglot.t('js.staff_search.modal.title_assignment')
  const modal_button_label = props.alreadyAssigned ? polyglot.t('js.staff_search.modal.add') : polyglot.t('js.staff_search.modal.assign')

  return (
    <div className="d-inline-block">
      <button
        type="button"
        className={button_class}
        onClick={() => setModalOpen(true)}>
        {button_label}
      </button>
      <form ref={formRef} action={props.actionUrl} method="post">
        <input type="hidden" name="authenticity_token" value={$.rails.csrfToken()} autoComplete="off"></input>
        {
          editingStaffs.map((option) => <input key={option.value} type="hidden" name="assigning_staff_ids[]" value={option.value}/>)
        }
      </form>
      <StaffSearchModal
        isOpen={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        title={modal_title}
        okLabel={modal_button_label}
        showSchedule={props.showSchedule}
        teamOptions={props.teamOptions}
        dutyOptions={props.dutyOptions}
        skillOptions={props.skillOptions}
        excludingStaffIds={props.excludingStaffIds}
        commentedStaffIds={props.commentedStaffIds}
        offeredStaffIds={props.offeredStaffIds}
        selectedStaffs={editingStaffs}
        onChangeSelected={(value) => setEditingStaffs(value)}
        disabled={sending}
      />
    </div>
  );
}

export default AssignButton;
