import $ from 'jquery';

async function updateUserInfo(userId: string) {
  const response = await fetch(`/stf/ac/contract_updates/current_contract?user_id=${userId}`);
  const html = await response.text();
  const placeHolder = document.getElementById('current-contract');
  if (placeHolder == null) return;
  placeHolder.innerHTML = html;
}

document.addEventListener('turbolinks:load', () => {
  // NOTE: select2 の変更検出は addEventListener だとできないので jQuery を使う
  $('.js-contract-update-user').on('change', (e) => {
    const select = e.currentTarget as HTMLSelectElement;
    console.log(`jq: changed: ${select.value}`);
    updateUserInfo(select.value);
  })
})
