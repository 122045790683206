import './lib/jquery';
import 'bootstrap-sass';
import './lib/jquery-dependants';
import './lib/turbolinks';
import './lib/beforeunload';
import './lib/bs_modal';
import './lib/toastify';
import 'deckar01-task_list';
import './workforce/init';
import './workforce/channels/related_comments';
import './workforce/channels/task_comments';
import './workforce/views/address_input';
import './workforce/views/ac_contract_updates';
import './workforce/views/comment_form';
import './workforce/views/companies';
import './workforce/views/staff_shifts';
import './workforce/views/bank_accounts';
import './workforce/views/inspinia';
import './workforce/views/profiles';
import './workforce/views/related_comments';
import './workforce/views/staff_form';
import './workforce/views/staffs';
import './workforce/views/routines';
import './workforce/views/task_calendar';
import './workforce/views/tasks';
import './workforce/views/tasks_projects';
import './workforce/views/tasks_quality_check';
import './workforce/views/tasks_tag_input';
import './workforce/views/time_transfer_logs';
import './workforce/views/users';
import './workforce/views/users_chatwork_bot';
import './workforce/views/users_main_user_fields';
import './workforce/views/user_bill_checker';
import './workforce/views/user_search';
import './workforce/views/user_registrations';
import './workforce/views/working_timetables';
import './workforce/views/working_timetables_staffs';
import './workforce/views/user_bills';
import './workforce/views/user_attachments';
import './workforce/views/user_edit';
import './workforce/shared/comment_reply';
import './workforce/shared/common_inputs';
import './workforce/shared/textarea';
import './workforce/shared/toggle';
import './workforce/shared/task_list';
import './workforce/shared/check_all';
import './workforce/shared/collapse_disable_fields';
import './workforce/shared/collapse_fields';
import './workforce/shared/file_input';
import './workforce/shared/file_input_style';
import './workforce/shared/free_text_select';
import './workforce/shared/ie_bug';
import './workforce/shared/mention';
import './workforce/shared/dnd';
import './workforce/shared/popover';
import './workforce/shared/scroll_to_comment';
import './lib/disable_number_wheeel'
import './workforce/init-react';
