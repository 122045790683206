import * as React from 'react'
import { Notification } from '../../models/Notification';
import NotificationItem from './NotificationItem';


type Props = {
  notifications: Notification[],
  onAction?: (notificationId: number) => void
}

const NotifiationList = (props: Props) => {
  const { notifications } = props;

  return (<ul className="notification-list unstyled">
    { notifications.map(notification => <NotificationItem key={notification.id} notification={notification} onAction={props.onAction} />) }
  </ul>);
};
export default NotifiationList;
