
const $tagTokenInputs = ['.js-token-input', '.js-routine-token-input'];
$(document).on('click', '.js-add-label', function() {
  const $label = $(this).html();
  $.each($tagTokenInputs, function(index, tokenInput) {
    const $tokenInput = $(tokenInput);
    if ($tokenInput.length > 0) {
      $tokenInput.tokenInput('add', { id: $label, name: $label });
    }
  });
});

$(document).on('change', '.js-tag-search-select', function(e) {
  const $selected = $(e.currentTarget).find('option:selected');
  $($(e.currentTarget).data('taskCategorySelector')).val($selected.data('taskCategoryId')).trigger('change');

  const $label = $selected.data('subTaskCategoryName');
  $.each($tagTokenInputs, function(index, tokenInput) {
    if ($(tokenInput).length > 0) {
      $(tokenInput).tokenInput('add', { id: $label, name: $label });
    }
  });
});

const fetchTaskCategoryTags = function() {
  const $taskCategorySelector = $('.js-task-category-selector');
  if ($taskCategorySelector.val() !== "") {
    $.ajax({
      type: 'GET',
      url: '/stf/task_categories/' + $taskCategorySelector.val() + '/tags',
      dataType: 'script'
    });
  }
};

const fetchRoutineTaskCategoryTags = function() {
  const $taskCategorySelector = $('.js-routine-task-category-selector');
  if ($taskCategorySelector.val() !== "") {
    $.ajax({
      type: 'GET',
      url: '/stf/task_categories/' + $taskCategorySelector.val() + '/tags',
      dataType: 'script'
    });
  }
};

$(document).on('change', '.js-task-category-selector', () => fetchTaskCategoryTags());
$(document).on('change', '.js-routine-task-category-selector', () => fetchRoutineTaskCategoryTags());

$(document).on('turbolinks:load', function() {
  if ($('.js-token-input').length > 0) {
    $('.js-token-input').tokenInput($('.js-fetch-urls').data('tags-url'), {
      theme: "workforce",
      tokenFormatter: (item) => `<li>${item.name}</li>`,
      hintText: null,
    }
    );
  }

  if ($('.js-routine-token-input').length > 0) {
    $('.js-routine-token-input').tokenInput($('.js-fetch-urls').data('tags-url'), {
      theme: "workforce",
      tokenFormatter: (item) => `<li>${item.name}</li>`,
    }
    );
  }

  if ($('.js-task-category-selector').length > 0) {
    fetchTaskCategoryTags();
  }

  if ($('.js-routine-task-category-selector').length > 0) {
    fetchRoutineTaskCategoryTags();
  }
});
