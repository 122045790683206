$(document).on('change', '.js-working-timetables-calendar-day-form-day-off-checkbox', function() {
  const date = $(this).data('date');
  const checked = $(this).prop('checked');
  $(`.js-working-timetables-calendar-day-form-start${date}`).prop('disabled', checked);
  $(`.js-working-timetables-calendar-day-form-end${date}`).prop('disabled', checked);
});

$(document).on('turbolinks:load', function() {
  $('.js-working-timetables-calendar-day').each(function() {
    const $this = $(this);
    $this.popover({
      placement: 'left',
      html: true,
      sanitize: false,
      animation: false,
      container: '#wrapper',
      content: () => $this.closest('.js-working-timetables-calendar-day-scope').find('.js-working-timetables-calendar-day-form-content').html(),
    });
    const hideAllPopovers = () => $('.js-working-timetables-calendar-day[aria-describedby]').popover('hide');
    $this.on('show.bs.popover', hideAllPopovers);
  });
});
