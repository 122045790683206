import $ from 'jquery';

function showTimeTransferSummary() {
  const userSelect = document.querySelector('.js-time-transfer-log-user-select')
  if (userSelect == null) return;

  const userId = userSelect.value;
  const summaryContainer = document.querySelector('.js-time-transfer-log-summary')

  if (userId !== "") {
    fetch(`/stf/users/${userId}/time_transfer_logs/summary`)
    .then((response) => response.text())
    .then((text) => summaryContainer.innerHTML = text);
  } else {
    summaryContainer.innerHTML = '';
  }
}

function toggleElement(className, show) {
  const element = document.querySelector(className);
  if (element == null) return;

  element.classList.toggle('d-none', !show);
  element.classList.toggle('d-block', show);
  element.querySelectorAll('input, select').forEach((input) => {
    input.disabled = !show;
  });
}

function updateTimeTransferMonths() {
  const transferTypeSelect = document.querySelector('.js-time-transfer-log-type-select');
  const forward = (transferTypeSelect.value === 'forward');

  toggleElement('.js-time-transfer-log-months-forward', forward);
  toggleElement('.js-time-transfer-log-months-backward', !forward);
}

document.addEventListener('turbolinks:load', () => {
  // NOTE: select2 の change イベントは addEventListener だと取れないので jQuery を使う
  const $userSelect = $('.js-time-transfer-log-user-select');
  if ($userSelect.length > 0) {
    $userSelect.on('change', showTimeTransferSummary);
    showTimeTransferSummary();
  }

  const transferTypeSelect = document.querySelector('.js-time-transfer-log-type-select');
  if (transferTypeSelect) {
    transferTypeSelect.addEventListener('change', updateTimeTransferMonths);
    updateTimeTransferMonths();
  }
})
