document.addEventListener('turbolinks:load', () => {
  if (/#comment_\d+/.test(window.location.hash)) {
    const id = window.location.hash.slice(1);
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      elem.classList.add('highlighted-comment');
    }
  }
});
