import $ from 'jquery';

$(document).on('change', '#js-company-service-type-id', (e) => {
  $.ajax({
    url: '/stf/companies/next_client_uid',
    dataType: 'json',
    method: 'GET',
    data: {
      service_type_id: $(e.currentTarget).val(),
    },
  })
  .then((data) => {
    $('.js-company-client-uid').val(data.next_client_uid)
  });
});
